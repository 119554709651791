import { useQuery } from "react-query";
import { useI18n } from "../../../translation";
import { CardTitle } from "../components/CardTitle";
import { MovementMatrixCell } from "./components/MovementMatrixCell";
import { PronePattern } from "./PronePattern";
import { SidelyingPattern } from "./SidelyingPattern";
import { SupinePattern } from "./SupinePattern";
import {
    MovementMatrixData,
    MOVEMENT_MATRIX_CELLS,
    MovementMatrixDataWithPattern,
} from "../../../types/MovementMatrixData";
import { MemberData } from "../../../types/MemberData";
import { ControlPattern } from "./components/ControlPattern";
import { authenticatedFetch } from "../../../service/api";
import {useState} from "react";
import {MdFullscreen, MdFullscreenExit} from "react-icons/md";
import {Modal} from "../../../components/Modal/Modal";

export const MovementMatrix = ({ member }: { member: MemberData }) => {
  const i18n = useI18n();
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const dummyData = Object.fromEntries(
      Object.entries(MOVEMENT_MATRIX_CELLS).map(([key, label], index) => [
          key,
          {
              category: {
                  id: `${index}`,
                  label,
                  key,
              },
              note: "",
              isFlagged: false,
              isLeftRight: false,
          },
      ])
  ) as MovementMatrixData;

  const {
    data: loadedData,
    isLoading,
    isError,
  } = useQuery<MovementMatrixData>(["movement-matrix", member.id], async () => {
    const response = await authenticatedFetch("member/" + member.id + "/movement-matrix");
    if (!response.ok) throw new Error();
    return response.json();
  });
  const data =
    isLoading || isError ? dummyData : (loadedData as MovementMatrixData);

  const Matrix = () => <div className="flex flex-1 flex-col border-2 rounded-xl">
    <CardTitle
      className="p-4"
      title={i18n["memberDetails.movementMatrix.title"]}
      onClick={() => setFullScreen(!fullScreen)}
      icon={fullScreen ? <MdFullscreenExit /> : <MdFullscreen />}
    />
    <div className={'flex flex-col ' + (fullScreen ? 'lg:flex-row' : 'xl:flex-row') + ' flex-1 border-t-2'}>
      <SupinePattern fullScreen={fullScreen} data={data} />
      <PronePattern fullScreen={fullScreen} data={data} />
      <SidelyingPattern fullScreen={fullScreen} data={data} />
    </div>
    <div className={'flex flex-col ' + (fullScreen ? 'lg:flex-row' : 'xl:flex-row')}>
      <MovementMatrixCell
        cellValue={data["iad-atmung"]}
        className="xl:border-r-2"
        label={i18n["memberDetails.movementMatrix.iad"]}
      />
      <MovementMatrixCell
        cellValue={data["hws"]}
        showDoubleRow
        label={i18n["memberDetails.movementMatrix.hws"]}
      />
    </div>
    <ControlPattern data={(data as MovementMatrixDataWithPattern)} />
  </div>

  return fullScreen ? (
    <Modal className="max-w-[100%]" closeModal={() => setFullScreen(false)}>
      <Matrix />
    </Modal>
  ) : (<Matrix />);
};
