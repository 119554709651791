import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useI18n } from "../../../translation";
import { HistoryListData } from "../../../types/HistoryListData";
import {
  HistoryList,
  UpdateDataProps,
} from "../components/HistoryList/HistoryList";
import { HistoryListContent } from "../components/HistoryList/HistoryListContent";
import { authenticatedFetch } from "../../../service/api";

export const TrainingHistory = () => {
  const i18n = useI18n();

  const { memberId } = useParams();
  const queryResult = useQuery<HistoryListData[]>(
    ["trainingHistory", memberId],
    async () => {
      const response = await authenticatedFetch(
        "member/" + memberId + "/training-history",
      );
      if (!response.ok) throw new Error();
      return response.json();
    }
  );

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async ({ data, type }: UpdateDataProps) => {
      let response = { ok: false } as Response;
      if (type === "ADD") {
        response = await authenticatedFetch(
          "member/" + memberId + "/training-history",
            'PUT',
            data,
        );
      }
      if (type === "UPDATE") {
        response = await authenticatedFetch(
            "member/" + memberId + "/training-history/" + data.id,
            'POST',
            data
        );
      }
      if (type === "DELETE") {
        response = await authenticatedFetch(
            "member/" + memberId + "/training-history/" + data.id,
            'DELETE'
        );
      }
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      onSuccess: async (data, { onSuccessCB }) => {
        await queryClient.invalidateQueries(["trainingHistory", memberId]);
        onSuccessCB();
      },
      onError: async () => {},
    }
  );

  return (
    <HistoryList
      title={i18n["memberDetails.trainingHistory.title"]}
      renderContent={({ openEntry }) => (
        <HistoryListContent queryResult={queryResult} openEntry={openEntry} />
      )}
      updateData={(props) => mutate(props)}
      isUpdating={isLoading}
    />
  );
};
