import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from "react";

type InputProps = {
  label: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { type, label, ...otherProps } = props;

  return (
    <div className="form-floating">
      <input
        ref={ref}
        type={type || "text"}
        className="form-control
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        id={label}
        placeholder={label}
        {...otherProps}
      />
      <label htmlFor={label} className="text-gray-700">
        {label}
      </label>
    </div>
  );
});
