import { Modal } from "../../../components/Modal/Modal";
import { DatePicker } from "../../../components/form/DatePicker";
import { Input } from "../../../components/form/Input";
import { Select } from "../../../components/form/Select";
import { useI18n } from "../../../translation";
import { MemberData } from "../../../types/MemberData";
import { Controller, useForm } from "react-hook-form";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { ModalFooter } from "../../../components/Modal/ModalFooter";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { ModalFooterButtons } from "../../../components/Modal/ModalFooterButtons";

export const MemberModal = ({
  label,
  onSubmit,
  isLoading,
  closeModal,
  defaultValues,
}: {
  label: string;
  onSubmit: (data: MemberData["info"]) => void;
  isLoading: boolean;
  closeModal: () => void;
  defaultValues?: MemberData["info"];
}) => {
  const i18n = useI18n();

  const genderOptions = [
    { value: "none", label: i18n["memberModal.gender.placeholder"] },
    { value: "m", label: i18n["memberModal.gender.male"] },
    { value: "f", label: i18n["memberModal.gender.female"] },
    { value: "d", label: i18n["memberModal.gender.diverse"] },
  ];

  const formMethods = useForm<MemberData["info"] & {mobeePersonId: string, isTestUser: boolean}>({ defaultValues });
  const { register, handleSubmit, control } = formMethods;

  return (
    <Modal closeModal={closeModal}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader title={label} closeModal={closeModal} />
        <ModalBody>
          <div className="flex flex-col gap-y-6">
            <Input
              label={i18n["memberModal.firstName"]}
              {...register("firstName", { required: true })}
            />
            <Input
              {...register("lastName", { required: true })}
              label={i18n["memberModal.lastName"]}
            />
            <Input
              label={i18n["email"]}
              {...register("email", { required: false })}
            />
            <Input
              label={i18n["memberModal.phoneNumber"]}
              {...register("phoneNumber", { required: false })}
            />
            <DatePicker
              {...register("birthday", { required: false })}
              label={i18n["memberModal.birthdate"]}
            />
            <Input
              label={i18n["memberModal.mobeePersonId"]}
              {...register("mobeePersonId", { required: false })}
            />
            <Controller
              control={control}
              name="isTestUser"
              render={({ field }) => (
                <Select controllerProps={field} options={[
                  {value: 'none', label: i18n["memberModal.isTestUser"], disabled: true},
                  {value: '0', label: 'Nein'},
                  {value: '1', label: 'Ja'},
                ]} />
              )}
            />
            <Controller
              control={control}
              name="gender"
              render={({ field }) => (
                <Select controllerProps={field} options={genderOptions} />
              )}
              rules={{ validate: (value) => !!value && value !== "none" }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons cancel={closeModal} isSubmitLoading={isLoading} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
