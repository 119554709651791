import { FaUser } from "react-icons/fa";
import { useI18n } from "../../translation";
import { CardTitle } from "./components/CardTitle";
import { FaPen } from "react-icons/fa";
import { MemberModal } from "./components/MemberModal";
import { useState } from "react";
import { getIsoDate, useDateUtils } from "../../utils/dateUtils";
import { MemberData } from "../../types/MemberData";
import { useMutation, useQueryClient } from "react-query";
import { authenticatedFetch } from "../../service/api";
import {Button} from "../../components/Button";
import {MemberHomeworkModal} from "./components/MemberHomeworkModal";
import {ProjectData} from "../../types/ProjectData";
import {toast} from "react-hot-toast";

export const MemberInfo = ({ data, project }: { data: MemberData, project: ProjectData|undefined }) => {
  const i18n = useI18n();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHomeworkModalOpen, setIsHomeworkModalOpen] = useState(false);
  const [showInternals, setShowInternals] = useState<boolean>(false);
  const [withConsentForHealthscore, setWithConsentForHealthscore] = useState<boolean>(false);
  const { getLocalizedDate, getAge } = useDateUtils();

  const { info } = data;
  const defaultValues = {
    ...info,
    birthday: getIsoDate(info.birthday)!,
    mobeePersonId: data.mobeePersonId,
  };

  const queryClient = useQueryClient();
  const updateMutation = useMutation(
    async (newInfo: MemberData["info"]) => {
      const response = await authenticatedFetch("member/" + data.id, 'POST', { ...newInfo });
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      onSuccess: async () => {
        setIsModalOpen(false);
        await queryClient.invalidateQueries(["member", data.id]);
      },
      onError: async () => {},
    }
  );
  const {mutate, isLoading} = useMutation(
    async () => {
      const response = await authenticatedFetch("member/" + data.id + '/sitzkrieger-account', 'POST', {
        withConsentForHealthscore: withConsentForHealthscore,
      });
      if (!response.ok) throw new Error();

      let json = await response.json();

      if (json.success === false && json.reason) {
        toast.error(json.reason);
        return {};
      }

      return json;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["member", data.id]);
      },
      onError: async (error, variables, context) => {
        console.log(error);
      },
    }
  );

  const onSubmit = (data: MemberData["info"]) => updateMutation.mutate(data);

  return (
    <div className="flex flex-1 min-w-[300px] flex-col p-4 border-2 rounded-xl">
      <CardTitle
        title={info.firstName + " " + info.lastName}
        onClick={() => setIsModalOpen(true)}
        icon={<FaPen />}
      />

      <div className="flex justify-center items-center mt-4 mb-6 rounded-full bg-gray-400 text-white leading-normal uppercase shadow-md w-24 h-24">
        <FaUser size={56} />
      </div>

      <p className="mb-3" onClick={() => setShowInternals(!showInternals)}>
        {i18n["memberDetails.memberInfo.email"] + ": " + info.email}
      </p>
      <p className="mb-3">
        {i18n["memberDetails.memberInfo.age"] + ": " + getAge(info.birthday)}
      </p>
      {showInternals ? <>
        <p className="mb-3">
          <>
            {i18n["memberDetails.memberInfo.testUser"]}
            &nbsp;
            <span dangerouslySetInnerHTML={{__html: (data.isTestUser ? '&#x2714;' : '&#x2717;')}} />
          </>
        </p>
        {data.sitzkriegerId > -1 && (<p className="mb-3">
          Internal ID: {data.sitzkriegerId}
        </p>)}
      </> : <></>}

      <p>
        {i18n["memberDetails.memberInfo.birthdate"] + ": " + getLocalizedDate(info.birthday)}
      </p>
      {typeof project !== 'undefined' && project.organization.sitzkriegerId !== 0 ? <>
        {(info.email || '') !== '' && data.sitzkriegerId > 0 ? <>
          <p className={"mt-5"}>
            <Button
              title={i18n["memberModal.sitzkriegerHomework.button"]}
              onClick={() => setIsHomeworkModalOpen(true)}
            />
          </p>
        </> : <>
          <p className={"mt-5"}>
            <label>
              <input type="checkbox" defaultChecked={withConsentForHealthscore} onClick={() => setWithConsentForHealthscore(!withConsentForHealthscore)} />
              {i18n["memberModal.sitzkriegerHomework.withConsentForHealthscore"]}
            </label>
          </p>
          <p className={"mt-2"}>
            <Button
              title={i18n["memberModal.addMemberToSitzkrieger.button"]}
              onClick={() => mutate()}
              isLoading={isLoading}
            />
          </p>
        </>}
      </> : <></>}

      {isModalOpen && (
        <MemberModal
          label={i18n["memberModal.title.edit"]}
          onSubmit={onSubmit}
          isLoading={updateMutation.isLoading}
          closeModal={() => setIsModalOpen(false)}
          defaultValues={defaultValues}
        />
      )}
      {isHomeworkModalOpen ? <>
        <MemberHomeworkModal
          label={i18n["memberModal.sitzkriegerHomework.button"]}
          closeModal={() => setIsHomeworkModalOpen(false)}
          member={data}
        />
      </> : <></>}
    </div>
  );
};
