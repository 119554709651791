import { useQuery } from "react-query";
import { ProjectData } from "../types/ProjectData";
import { useI18n } from "../translation";
import { authenticatedFetch } from "./api";

export const useProjects = () => {
  const i18n = useI18n();
  const { data, isLoading } = useQuery<ProjectData[]>(
    ["projects"],
    async () => {
      const response = await authenticatedFetch('projects');
      if (!response.ok) throw new Error();
      return response.json();
    }
  );
  const allProjects = data || [];
  const allProjectOptions = [
    {
      value: "",
      label: i18n["memberModal.company.placeholder"],
    },
    ...allProjects.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  ];

  return { allProjects, allProjectOptions, isLoading };
};
