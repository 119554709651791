import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import {useAuth} from "./Authentication";

export const RequireAuthentication = ({children}: { children: JSX.Element }) => {
  const location = useLocation();
  const auth = useAuth();

  if (!auth?.token) {
    return <Navigate to='/login' state={{from: location}} replace/>
  }
  return children;
}
