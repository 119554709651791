import { ReactNode } from "react";
import { CircleButton } from "../../../components/CircleButton";

export const CardTitle = ({
  title,
  onClick,
  icon,
  className,
}: {
  title: string;
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
}) => {
  return (
    <div className={className}>
      <div className="flex flex-1">
        <div className="flex flex-1 text-2xl h-9">{title}</div>
        {onClick ? <CircleButton onClick={onClick} icon={icon} /> : null}
      </div>
      <div className="h-1 w-20 bg-brand-primary"></div>
    </div>
  );
};
