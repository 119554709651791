import { ControllerRenderProps } from "react-hook-form";
import { SelectOptions } from "../../types/SelectOptions";

type SelectProps = {
  options: SelectOptions[];
  controllerProps?: ControllerRenderProps<any, any>;
  onChange?: (newValue: string) => void;
  value?: string;
};

export const Select = (props: SelectProps) => {
  const { options, controllerProps } = props;
  const onChange = controllerProps?.onChange || props.onChange;
  const value = controllerProps?.value || props.value;

  return (
    <select
      ref={controllerProps?.ref}
      value={value}
      onChange={(event) => onChange && onChange(event.target.value)}
      className="form-select  appearance-none
          block
          w-full
          px-3
          py-4
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding bg-no-repeat
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
      aria-label="Default select example"
    >
      {options.map(({ value, label, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {label}
        </option>
      ))}
    </select>
  );
};
