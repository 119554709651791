import { useI18n } from "../translation";

// simple ts type guard
export const isValidDate = (date: any): date is Date => {
  return date instanceof Date && !isNaN(+date);
};

export const getShortDatePieces = (date: Date) => {
  const day = `${date.getDate()}`.padStart(2, "0");
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const year = `${date.getFullYear()}`.slice(-2);
  const fullYear = date.getFullYear();
  return { day, month, year, fullYear };
};

export const getIsoDate = (date: Date | string) => {
  const dateForConversion = typeof date === "string" ? new Date(date) : date;
  if (!isValidDate(dateForConversion)) return undefined;
  const { day, month, fullYear } = getShortDatePieces(dateForConversion);
  return `${fullYear}-${month}-${day}`;
};

export const useDateUtils = () => {
  const i18n = useI18n();

  const getLocalizedDate = (date: Date | string) => {
    const dateToLocalize = typeof date === "string" ? new Date(date) : date;
    if (!isValidDate(dateToLocalize)) return i18n["error.date"];
    return dateToLocalize.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // Not accurate
  const getAge = (date: Date | string) => {
    const birthdate = typeof date === "string" ? new Date(date) : date;
    if (!isValidDate(birthdate)) return i18n["error.date"];

    // time info gets removed
    const birthdayOnlyDate = new Date(getIsoDate(birthdate)!);
    const nowOnlyDate = new Date(getIsoDate(new Date())!);
    const diff = nowOnlyDate.getTime() - birthdayOnlyDate.getTime();
    const ageDate = new Date(diff);
    return `${Math.abs(ageDate.getUTCFullYear() - 1970)}`;
  };

  return { getLocalizedDate, getAge };
};
