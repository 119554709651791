import { ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "./Spinner";

export const Button = ({
  title,
  type,
  onClick,
  isDisabled,
  isLoading,
  className,
}: {
  title: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
}) => {
  const defaultClassNames =
    "px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md transition duration-150 ease-in-out active:shadow-lg hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0";

  return (
    <button
      onClick={onClick}
      type={type || "button"}
      className={twMerge(
        defaultClassNames,
        "bg-brand-primary hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-600 relative",
        className,
        (isDisabled || isLoading) && "pointer-events-none bg-opacity-60"
      )}
      disabled={isDisabled || isLoading}
    >
      {isLoading ? (
        <Spinner className="absolute inset-0 m-auto w-6 h-6" />
      ) : null}
      {title}
    </button>
  );
};
