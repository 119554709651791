import { useI18n } from "../../translation";

export const ErrorPage = () => {
  const i18n = useI18n();

  return (
    <div className="flex justify-center">
      <p className="p-2 pr-6 text-5xl font-semibold text-brand-primary">
        {i18n["errorPage.status"]}
      </p>
      <div className="flex flex-col border-l-2 p-2 pl-6">
        <h1 className="text-5xl font-semibold">{i18n["errorPage.title"]}</h1>
        <p className="text-slate-500 mt-1">{i18n["errorPage.subtitle"]}</p>
      </div>
    </div>
  );
};
