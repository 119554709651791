import {Outlet} from "react-router-dom";
import {useState} from "react";
import {useAuth} from "./Authentication";

const Layout = () => {
  const [showNavigation, setShowNavigation] = useState<boolean>(false);
  const auth = useAuth();

  let logo = "https://sitzkrieger.com/static/Logo_Head.png";
  let width = 55;
  let height =  55;
  let customLogo = false;
  if ((auth.trainer?.studio?.logo || '') !== '') {
    logo = auth.trainer!.studio.logo!;
    customLogo = true;
  }
  if (customLogo && (auth.trainer?.studio?.logoWidth || 0) > 0) {
    width = auth.trainer!.studio.logoWidth!;
  }
  if (customLogo && (auth.trainer?.studio?.logoHeight || 0) > 0) {
    height = auth.trainer!.studio.logoHeight!;
  }

  return <div>
    <div
      className="z-50 sticky top-0 flex flex-row justify-between items-center space-x-4 bg-white py-6 px-6 shadow-bottom">
      <a href="/" className="flex flex-row items-center">
        <img alt="Sitzkrieger.com" src={logo}
             style={{width: width + 'px', height: height + 'px'}}/>
        <span className="mx-2 text-brand-primary font-bold text-xl">Trainer-Software</span>
      </a>

      <div className="flex">
        <div
          className="text-brand-primary bg-white text-right z-20 md:flex flex-col md:flex-row font-bold uppercase justify-end">
          <div className="relative inline-block mt-auto" aria-expanded="true"
               aria-haspopup="true" onClick={() => setShowNavigation(!showNavigation)}>
            <div className="hover:text-brand-secondary duration-500 transition-colors" data-nav="skip">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20"
                   fill="currentColor">
                <path fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                      clipRule="evenodd"></path>
              </svg>
            </div>
            <div
              className={"origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none " + (showNavigation ? 'block' : 'hidden')}
              role="menu" aria-orientation="vertical">
              <div className={"p-2 text-md flex flex-col" + (showNavigation ? '' : ' hidden')}>
                <a className="mt-2 hover:text-brand-secondary duration-500 transition-colors"
                   href={process.env.REACT_APP_SITZKRIEGER_HOST}>
                  Zu Sitzkrieger
                </a>
                {auth.token ? <>
                  <button className="mt-2 hover:text-brand-secondary duration-500 transition-colors"
                          onClick={() => auth.signOut(() => window.location.replace(process.env.REACT_APP_SITZKRIEGER_HOST + '/mein-sitzkrieger/logout'))}>
                    Logout
                  </button>
                </> : <>
                  <button className="mt-2 hover:text-brand-secondary duration-500 transition-colors"
                          onClick={() => window.location.replace(process.env.REACT_APP_SITZKRIEGER_HOST + '/mein-sitzkrieger/login')}>
                    Login
                  </button>
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container mx-auto my-10 max-w-[80%]">
      <Outlet/>
    </div>
  </div>;
}

export default Layout;
