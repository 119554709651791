import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const ModalFooter = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        "modal-footer flex flex-shrink-0 flex-wrap items-center p-4 border-t border-gray-200 rounded-b-md gap-x-4 justify-end",
        className
      )}
    >
      {children}
    </div>
  );
};
