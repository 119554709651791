import {useEffect, useState} from "react";
import {twMerge} from "tailwind-merge";
import {
  BtnBold,
  BtnBulletList,
  BtnItalic, BtnLink,
  BtnNumberedList, BtnUnderline,
  Editor,
  EditorProvider, Separator,
  Toolbar,
  useEditorState
} from "react-simple-wysiwyg";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";

type TextareaProps = {
  id: string;
  className: string;
  label: string;
  field: ControllerRenderProps<any, any>
};

const UpdateEditorComponent = (props: {className: string}) => {
  const editorState = useEditorState();
  if (editorState.$el) {
    editorState.$el.className = twMerge("prose overflow-auto w-full", props.className) + " rsw-ce";
  }
  return <></>
};

export const Textarea = (props: TextareaProps) => {
  const [html, setHtml] = useState(props.field.value || '');

  useEffect(() => {
    props.field.onChange({target: {value: html}});
  }, [html, props.field]);

  return <>
    <EditorProvider>
      <UpdateEditorComponent className={props.className} />
      <Editor
        placeholder={props.label}
        value={html}
        onChange={(event) => setHtml(event.target.value)}
      >
        <Toolbar>
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <Separator />
          <BtnBulletList />
          <BtnNumberedList />
          <Separator />
          <BtnLink />
        </Toolbar>
      </Editor>
    </EditorProvider>
  </>;
};
