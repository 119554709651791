import { useState } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { useI18n } from "../../../translation";
import { CardTitle } from "../components/CardTitle";
import { HistoryListContainer } from "../components/HistoryList/HistoryListContainer";
import { TestingArchiveModalOptions } from "./TestingArchiveModalOptions";
import { FmsModalContent } from "./FmsModalContent/FmsModalContent";
import { UploadModalContent } from "./UploadModalContent/UploadModalContent";
import { TestingArchiveContent } from "./TestingArchiveContent";
import {
  TestingArchiveData,
  TestingArchiveFileFormData,
  TestingArchiveFMSFormData,
} from "../../../types/TestingArchiveData";
import { getIsoDate } from "../../../utils/dateUtils";

export type TestingArchiveModalData =
  | {
      type: "options";
      isNewEntry?: false;
    }
  | TestingArchiveModalFMSData
  | TestingArchiveModalFileData;

export type TestingArchiveModalFMSData =
  | {
      defaultValues: TestingArchiveData;
      type: "fms";
      isNewEntry?: false;
    }
  | {
      defaultValues: TestingArchiveFMSFormData;
      type: "fms";
      isNewEntry: true;
    };

export type TestingArchiveModalFileData =
  | {
      defaultValues: TestingArchiveData;
      type: "file";
      isNewEntry?: false;
    }
  | {
      defaultValues: TestingArchiveFileFormData;
      type: "file";
      isNewEntry: true;
    };

export const TestingArchive = () => {
  const i18n = useI18n();
  const [modalData, setModalData] = useState<TestingArchiveModalData>();
  const [isContentEditable, setIsContentEditable] = useState(false);

  const addNewEntry = () => {
    setIsContentEditable(true);
    setModalData({ type: "options" });
  };

  const openEntry = (defaultValues: TestingArchiveData) => {
    setIsContentEditable(false);
    if (defaultValues.archiveType === "fms") {
      setModalData({ type: defaultValues.archiveType, defaultValues });
    } else setModalData({ type: defaultValues.archiveType, defaultValues });
  };

  const switchMode = () => setIsContentEditable((old) => !old);
  const cancel = () => {
    const { isNewEntry } = modalData || {};
    if (isNewEntry) setModalData({ type: "options" });
    else if (isContentEditable) setIsContentEditable(false);
    else setModalData(undefined);
  };
  const closeModal = () => {
    setModalData(undefined);
  };

  const modalClassName =
    modalData?.type === "fms" && isContentEditable
      ? "mx-auto absolute inset-0 overflow-hidden [&>div]:h-full"
      : "";

  return (
    <HistoryListContainer>
      <CardTitle
        title={i18n["memberDetails.testingArchive.title"]}
        onClick={addNewEntry}
      />
      <TestingArchiveContent openEntry={openEntry} />

      {modalData ? (
        <Modal className={modalClassName} showLargeModal={modalData?.type === "fms" && isContentEditable} closeModal={closeModal}>
          {modalData?.type === "options" ? (
            <TestingArchiveModalOptions
              openFmsForm={() =>
                setModalData({
                  type: "fms",
                  defaultValues: { categoryRatings: {} },
                  isNewEntry: true,
                })
              }
              openUploadForm={() =>
                setModalData({
                  type: "file",
                  defaultValues: {
                    datetime: getIsoDate(new Date())!,
                    fileId: "",
                    type: "file",
                  },
                  isNewEntry: true,
                })
              }
            />
          ) : null}

          {modalData?.type === "fms" ? (
            <FmsModalContent
              isContentEditable={isContentEditable}
              modalData={modalData}
              title={i18n["memberDetails.testingArchive.fms.title"]}
              closeModal={closeModal}
              cancel={cancel}
              switchMode={switchMode}
            />
          ) : null}

          {modalData?.type === "file" ? (
            <UploadModalContent
              isContentEditable={isContentEditable}
              modalData={modalData}
              title={i18n["memberDetails.testingArchive.upload.title"]}
              closeModal={closeModal}
              cancel={cancel}
              switchMode={switchMode}
            />
          ) : null}
        </Modal>
      ) : null}
    </HistoryListContainer>
  );
};
