import {Controller, useFormContext} from "react-hook-form";
import { Textarea } from "../../../../../components/form/Textarea";
import { useI18n } from "../../../../../translation";
import { FmsRadioColumn } from "./FmsRadioColumn";
import { FmsRadioColumnDoubler } from "./FmsRadioColumnDoubler";
import {CSSProperties} from "react";

export const FmsContentRow = ({
  categoryKey,
  title,
  imgSrc,
  imgStyle,
  showDoubleRow,
  hideClearing,
}: {
  categoryKey: string;
  title: string;
  imgSrc: string;
  imgStyle?: CSSProperties,
  showDoubleRow?: boolean;
  hideClearing?: boolean;
}) => {
  const i18n = useI18n();
  const { control } = useFormContext();

  const headingClassName = "font-normal leading-tight text-lg mt-0 mb-4";
  const radioInputClassName =
    "flex-col items-center flex-initial [&>*]:mr-0 [&>label]:mb-2";

  return (
    <div>
      <p className={headingClassName}>{title}</p>
      <div className="flex flex-1 flex-col gap-8">
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex gap-8 w-[256px]">
            <img
              className="h-36 w-full flex-shrink-0"
              alt={title + "_img"}
              src={imgSrc}
              style={imgStyle}
            />
          </div>

          {showDoubleRow ? (
            <FmsRadioColumnDoubler
              categoryKey={categoryKey}
              renderColumn={(isLeft) => (
                <FmsRadioColumn
                  isLeft={isLeft}
                  categoryKey={categoryKey}
                  radioInputClassName={radioInputClassName}
                  hideClearing={hideClearing}
                />
              )}
            />
          ) : (
            <FmsRadioColumn
              categoryKey={categoryKey}
              radioInputClassName={radioInputClassName}
              hideClearing={hideClearing}
            />
          )}
        </div>

        <div className="flex flex-1 mr-2">
          <div className={"self-center rounded-2xl w-full"}>
            <Controller
              render={({field}) =>
                <Textarea field={field}
                          id={'memberDetails-testingArchive-fms-comment'}
                          className=""
                          label={i18n["memberDetails.testingArchive.fms.comment"]}
                />
              }
              name={`categoryRatings.${categoryKey}.comment`}
              control={control}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
