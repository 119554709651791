import { ReactNode } from "react";
import ReactModal from "react-modal";
import { twMerge } from "tailwind-merge";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: 99999,
    height: '100%',
    overflow: 'auto'
  },
  content: {
    display: "contents",
  },
};

export const Modal = ({
  closeModal,
  children,
  className,
  showLargeModal,
}: {
  closeModal: () => void;
  children?: ReactNode;
  className?: string;
  showLargeModal?: boolean;
}) => {

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={closeModal}
      style={customStyles}
      // removes console error
      appElement={document.getElementById("root") || undefined}
    >
      <div
        className={twMerge(
          "modal-dialog relative w-auto pointer-events-none",
          showLargeModal ? 'max-w-[90%]' : '',
          className
        )}
      >
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          {children}
        </div>
      </div>
    </ReactModal>
  );
};
