import React, {useEffect} from "react";
import {AuthenticationContext} from "../../types/Authentication";
import {sitzkriegerAuthProvider} from "../../utils/authentication";
import {getTrainerData, isAuthenticated} from "../../service/api";
import {TrainerData} from "../../types/TrainerData";

let AuthContext = React.createContext<AuthenticationContext>(null!);

export function useAuth(): AuthenticationContext {
  return React.useContext(AuthContext);
}

export function AuthProvider({children}: { children: React.ReactNode }) {
  let [token, setToken] = React.useState<string | null>(localStorage.getItem('sitzkrieger-token') || null);
  let [trainerData, setTrainerData] = React.useState<TrainerData|null>(null);

  const checkAuthentication = async () => {
    const tokenToCheck = token || localStorage.getItem('sitzkrieger-token') || '';
    const authenticated = await isAuthenticated(tokenToCheck);
    if (!authenticated) {
      setToken(null);
    } else {
      setToken(localStorage.getItem('sitzkrieger-token'));
    }
  }

  useEffect(() => {
    checkAuthentication().then();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token !== null) {
      getTrainerData(token).then(trainerData => setTrainerData(trainerData));
    }
  }, [token]);

  let signIn = async (username: string, password: string, callback: VoidFunction) => {
    return sitzkriegerAuthProvider.signin(username, password, (token) => {
      setToken(token);
      callback();
    });
  };

  let signOut = (callback: VoidFunction) => {
    return sitzkriegerAuthProvider.signout(() => {
      setToken(null);
      callback();
    });
  };

  let value = {token, signIn, signOut, trainer: trainerData};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
