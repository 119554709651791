import { useState } from "react";
import { Select } from "../../components/form/Select";
import { useI18n } from "../../translation";
import { SearchBar } from "../../components/search/SearchBar";
import { MemberSearchResults } from "../../components/search/MemberSearchResults";
import { HomeStep } from "./HomeStep";
import { useProjects } from "../../service/useProject";
import {AddMember} from "../memberDetails/components/AddMember";

export const Home = () => {
  const i18n = useI18n();

  const { allProjectOptions } = useProjects();
  const [projectId, setProjectId] = useState("");
  const [searchText, setSearchText] = useState("");
  const isStepDisabled = !projectId;

  return (
    <div className="flex flex-1 flex-col justify-center gap-16 md:w-8/12 lg:w-7/12">
      <div>
        <h2 className="text-gray-400 mb-1">{i18n["home.preTitle"]}</h2>
        <h1 className="text-5xl font-semibold mb-4">
          {i18n["home.welcomeBack"]}
        </h1>
        <div className="w-full h-[1px] bg-gray-300" />
      </div>

      <HomeStep step={1} title={i18n["home.step1"]}>
        <Select
          options={allProjectOptions}
          value={projectId}
          onChange={(newValue) => setProjectId(newValue)}
        />
      </HomeStep>

      <HomeStep step={2} title={i18n["home.step2a"]} isDisabled={isStepDisabled}>
        <SearchBar
          className="[&_input]:py-4"
          searchText={searchText}
          setSearchText={setSearchText}
          isDisabled={isStepDisabled}
        >
          <MemberSearchResults projectId={projectId} searchText={searchText} />
        </SearchBar>
      </HomeStep>

      <HomeStep step={2} title={i18n["home.step2b"]} isDisabled={isStepDisabled}>
          <AddMember projectId={projectId} />
      </HomeStep>
    </div>
  );
};
