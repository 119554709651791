import { Dispatch, ReactNode, SetStateAction } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import { useI18n } from "../../translation";

export const SearchBar = ({
  searchText,
  setSearchText,
  className,
  children,
  isDisabled,
}: {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  className?: string;
  children?: ReactNode;
  isDisabled?: boolean;
}) => {
  const i18n = useI18n();

  return (
    <div className={twMerge("flex flex-col relative", className)}>
      <div className="flex relative">
        <input
          type="search"
          className="form-control flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none pr-16"
          placeholder={i18n["search"]}
          aria-label="Search"
          aria-describedby="button-addon2"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          disabled={isDisabled}
        />
        <span
          className="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded absolute right-2 top-0 bottom-0"
          id="basic-addon2"
        >
          {searchText.length === 0 ?
            <FaSearch className="w-4" /> :
            <FaPlus className="w-4 rotate-45" onClick={() => setSearchText('')} />
          }
        </span>
      </div>

      {searchText ? children : null}
    </div>
  );
};
