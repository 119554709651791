import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "./Authentication";
import {useState} from "react";
import {Button} from "../../components/Button";

export const Login = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const from = location.state?.from?.pathname || "/";
  if (!auth.token) {
    const fromQuery = from === '/' ? '' : ('?from=' + from);
    window.location.replace(process.env.REACT_APP_SITZKRIEGER_HOST + '/mein-sitzkrieger/login' + fromQuery);
  }

  return <>
    <div>
      Automatische Weiterleitung zu Sitzkrieger...
    </div>
    <form style={{display: 'none'}} onSubmit={(e) => {
      e.preventDefault();
      auth.signIn(username, password, () => {
        setTimeout(() => navigate(from, {replace: true}), 100);
      });
    }} className="max-w-lg m-auto">
      <h1 className="text-4xl text-brand-gray font-light my-2">Trainer Login</h1>
      <p>Logge dich mit deinem Sitzkrieger-Account ein.</p>
      <br />
      <label className="block mb-2">
        <span className="text-brand-gray text-sm font-bold">Benutzername</span>
        <input
          required
          className="form-control flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none pr-16"
          value={username} onChange={(v) => setUsername(v.target.value)}/>
      </label>
      <label className="block mb-2">
        <span className="text-brand-gray text-sm font-bold ">Passwort</span>
        <input
          required
          type="password"
          className="form-control flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none pr-16"
          value={password} onChange={(v) => setPassword(v.target.value)}/>
      </label>
      <p className="text-sm mb-2"><i>Hinweis: Im Normalfall ist der Benutzername und die E-Mail gleich</i></p>
      <Button type={"submit"} title={'Einloggen'} isDisabled={!username || !password}/>
    </form>
  </>
};
