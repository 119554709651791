import {FaPen, FaTrash} from "react-icons/fa";
import {twMerge} from "tailwind-merge";
import {CircleButton} from "../../../../components/CircleButton";
import {ModalBody} from "../../../../components/Modal/ModalBody";
import {ModalHeader} from "../../../../components/Modal/ModalHeader";
import {Spinner} from "../../../../components/Spinner";
import {useI18n} from "../../../../translation";
import {HistoryListData, HistoryListDataAnamnesis} from "../../../../types/HistoryListData";
import {useDateUtils} from "../../../../utils/dateUtils";
import {UpdateDataProps} from "./HistoryList";
import parse from 'html-react-parser';
import {useEffect, useState} from "react";
import {useQueryClient} from "react-query";
import {useParams} from "react-router-dom";
import {TestingArchiveData} from "../../../../types/TestingArchiveData";
import {FmsModalContent} from "../../TestingArchive/FmsModalContent/FmsModalContent";
import {Button} from "../../../../components/Button";

export const HistoryListModalContent = ({
  data,
  updateData,
  isUpdating,
  title,
  switchMode,
  closeModal,
  showLargeModal,
}: {
  data: HistoryListData | HistoryListDataAnamnesis;
  updateData: (props: UpdateDataProps) => void;
  isUpdating: boolean;
  title: string;
  switchMode: () => void;
  closeModal: () => void;
  showLargeModal: (isLarge: boolean) => void;
}) => {
  const [activeTestingArchiveReference, setActiveTestingArchiveReference] = useState<string | null>(null);
  const [element, setElement] = useState<TestingArchiveData>();
  const [isFmsEditing, setIsFmsEditing] = useState<boolean>(false);
  const i18n = useI18n();
  const cache = useQueryClient().getQueryCache();
  const {memberId} = useParams();
  const {getLocalizedDate} = useDateUtils();

  useEffect(() => {
    if (activeTestingArchiveReference === null) {
      setIsFmsEditing(false);
    }
    const testingArchive = cache.find(['testingArchive', memberId])?.state?.data;
    if (testingArchive) {
      Object.values(testingArchive).forEach((e: TestingArchiveData) => {
        if (e.id === activeTestingArchiveReference) {
          setElement(e);
        }
      });
    }
  }, [activeTestingArchiveReference, cache, memberId]);

  // eslint-disable-next-line
  useEffect(() => showLargeModal(isFmsEditing), [isFmsEditing]);


  const headingClassName = "font-medium leading-tight text-lg mt-0";
  const descriptionClassName = "mt-1";

  const onDelete = () => {
    updateData({data, type: "DELETE", onSuccessCB: closeModal});
  };

  return (
    <div>
      {activeTestingArchiveReference ?
        <div>
          {element ? <>
            <FmsModalContent
              isContentEditable={isFmsEditing}
              modalData={{defaultValues: element, type: 'fms', isNewEntry: false}}
              title={i18n["memberDetails.testingArchive.fms.title"]}
              closeModal={() => setActiveTestingArchiveReference(null)}
              cancel={() => setActiveTestingArchiveReference(null)}
              switchMode={() => setIsFmsEditing(!isFmsEditing)}
            />
          </> : <></>}
        </div>
        : <>
          <ModalHeader title={title} closeModal={closeModal}/>
          <ModalBody>
            {isUpdating ? <Spinner className="absolute inset-0 m-auto"/> : null}
            <div
              className={twMerge(
                "flex flex-col gap-y-6 pb-16",
                isUpdating && "opacity-60"
              )}
            >
              {
                data.readonly || false ? <></> : <>
                  <div className="flex flex-1 justify-end gap-x-6">
                    <CircleButton
                      className="bg-brand-gray hover:bg-brand-red1 focus:bg-brand-red1"
                      onClick={onDelete}
                      icon={<FaTrash/>}
                    />
                    <CircleButton onClick={switchMode} icon={<FaPen/>}/>
                  </div>
                </>
              }
              <div>
                <p className={headingClassName}>
                  {i18n["memberDetails.trainingHistory.modal.form.title"]}
                </p>
                <p className={descriptionClassName}>{data.title}</p>
              </div>
              <div>
                <p className={headingClassName}>{i18n["date"]}</p>
                <p className={descriptionClassName}>
                  {getLocalizedDate(data.datetime)}
                </p>
              </div>
              <div>
                <p className={headingClassName}>
                  {i18n["memberDetails.trainingHistory.modal.form.notes"]}
                </p>
                <div className={descriptionClassName + ' prose'}>{data.note ? parse(data.note) : ''}</div>
                { // @ts-ignore
                  data.hasOwnProperty('reference') && data?.reference ?
                  <Button
                    title="Zum FMS Eintrag"
                    onClick={() => setActiveTestingArchiveReference((data as HistoryListDataAnamnesis).reference.eventId)}/> : <></>}
              </div>
            </div>
          </ModalBody>
        </>
      }
    </div>
  );
};
