import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { RadioInput } from "../../../../../components/form/RadioInput";
import { useI18n } from "../../../../../translation";

export const FmsRadioColumn = ({
  categoryKey,
  isLeft,
  radioInputClassName,
  hideClearing,
}: {
  categoryKey: string;
  isLeft?: boolean;
  radioInputClassName: string;
  hideClearing?: boolean,
}) => {
  const i18n = useI18n();
  const coloredStripeClassName = "w-16 h-6 mt-4";

  const { setValue, watch } = useFormContext();
  const getPointsProperty = () => {
    if (isLeft === null || typeof isLeft === 'undefined') {
      return `categoryRatings.${categoryKey}.score`;
    }
    if (isLeft) return `categoryRatings.${categoryKey}.scoreLeft`;
    if (!isLeft) return `categoryRatings.${categoryKey}.scoreRight`;
    return `categoryRatings.${categoryKey}.score`;
  };
  const selectedValue = watch(getPointsProperty());

  return (
    <div className="flex">
      <div className={twMerge("flex gap-6 mr-4")}>
        <RadioInput
          name={categoryKey + getPointsProperty()}
          value={1}
          selectedValue={selectedValue}
          setSelectedValue={(newValue) =>
            setValue(getPointsProperty(), newValue, { shouldDirty: true })
          }
          className={twMerge(radioInputClassName, "justify-start")}
          label={i18n["memberDetails.movementMatrix.pain.short"]}
        />
        {hideClearing ? <></> : <RadioInput
          name={categoryKey + getPointsProperty()}
          value={2}
          selectedValue={selectedValue}
          setSelectedValue={(newValue) =>
            setValue(getPointsProperty(), newValue, { shouldDirty: true })
          }
          className={twMerge(radioInputClassName, "justify-start")}
          label={i18n["memberDetails.testingArchive.fms.clearing.short"]}
        />}
      </div>

      <div className="flex flex-col">
        <RadioInput
          name={categoryKey + getPointsProperty()}
          value={3}
          selectedValue={selectedValue}
          setSelectedValue={(newValue) =>
            setValue(getPointsProperty(), newValue, { shouldDirty: true })
          }
          className={radioInputClassName}
          label={i18n["memberDetails.testingArchive.fms.rating1.short"]}
        />
        <div className={twMerge(coloredStripeClassName, "bg-red-300")} />
      </div>
      <div className="flex flex-col">
        <RadioInput
          name={categoryKey + getPointsProperty()}
          value={4}
          selectedValue={selectedValue}
          setSelectedValue={(newValue) =>
            setValue(getPointsProperty(), newValue, { shouldDirty: true })
          }
          className={radioInputClassName}
          label={i18n["memberDetails.testingArchive.fms.rating2.short"]}
        />
        <div className={twMerge(coloredStripeClassName, "bg-yellow-200")} />
      </div>
      <div className="flex flex-col">
        <RadioInput
          name={categoryKey + getPointsProperty()}
          value={5}
          selectedValue={selectedValue}
          setSelectedValue={(newValue) =>
            setValue(getPointsProperty(), newValue, { shouldDirty: true })
          }
          className={radioInputClassName}
          label={i18n["memberDetails.testingArchive.fms.rating3.short"]}
        />
        <div className={twMerge(coloredStripeClassName, "bg-green-300")} />
      </div>
    </div>
  );
};
