import { FaStar, FaInfoCircle } from "react-icons/fa";
import { SingleRadioRow } from "./SingleRadioRow";
import { DoubleRadioRow } from "./DoubleRadioRow";
import { useState } from "react";
import { MovementMatrixCellModal } from "./MovementMatrixCellModal";
import { twMerge } from "tailwind-merge";
import {
  MovementMatrixCellValue,
  MovementMatrixData,
} from "../../../../types/MovementMatrixData";
import { useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { authenticatedFetch } from "../../../../service/api";
import {Spinner} from "../../../../components/Spinner";
import {toast} from "react-hot-toast";
import {useI18n} from "../../../../translation";

export type UpdateCellValueProps = {
  newValue: 0 | 1 | 2 | 3;
  isLeft?: boolean;
};

export const MovementMatrixCell = ({
  cellValue,
  label,
  isHalfSize,
  showDoubleRow,
  className,
}: {
  cellValue: MovementMatrixCellValue;
  label: string;
  isHalfSize?: boolean;
  showDoubleRow?: boolean;
  className?: string;
}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const i18n = useI18n();

  const radioRowClassName = twMerge(
    isHalfSize && "pb-4",
    isHalfSize && showDoubleRow && "pb-1"
  );

  const getNewValueObj = (newValue: 0 | 1 | 2 | 3, isLeft?: boolean) => {
    if (isLeft) return { pointsLeft: newValue };
    if (isLeft === false) return { pointsRight: newValue };
    return { points: newValue };
  };

  const { memberId } = useParams();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async ({ newValue, isLeft }: { newValue: 0 | 1 | 2 | 3; isLeft?: boolean }) => {
      const response = await authenticatedFetch(
          "member/" +
          memberId +
          "/movement-matrix/item/" +
          cellValue?.category.id +
          "/points",
          'POST',
          getNewValueObj(newValue, isLeft)
      );
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      // When mutate is called:
      onMutate: async ({ newValue, isLeft }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["movement-matrix", memberId]);

        // Snapshot the previous value
        const previousValue = queryClient.getQueryData([
          "movement-matrix",
          memberId,
        ]) as MovementMatrixData;

        const updatedValue = structuredClone(
          previousValue
        ) as MovementMatrixData;
        // @ts-ignore
        updatedValue[cellValue.category.key] = {
          // @ts-ignore
          ...updatedValue[cellValue.category.key],
          isLeftRight: isLeft as boolean,
          ...getNewValueObj(newValue, isLeft),
        };
        // Optimistically update to the new value
        queryClient.setQueryData(["movement-matrix", memberId], updatedValue);

        // Return a context with the previous and new todo
        return { previousValue, updatedValue };
      },
      // If the mutation fails, use the context we returned above
      onError: (err, newTodo, context) => {
        queryClient.setQueryData(
          ["movement-matrix", memberId],
          context?.previousValue
        );
        toast.error(i18n['notifications.on-error']);
      },
      onSuccess: () => {
        toast.success(i18n['notifications.matrix.cell.success']);
      },
      // Always refetch after error or success:
      onSettled: () => {
        if (
          queryClient.isMutating({ mutationKey: "updateMovementMatrix" }) === 1
        ) {
          queryClient.invalidateQueries(["movement-matrix", memberId]);
        }
      },
      retry: 3,
      mutationKey: "updateMovementMatrix",
    }
  );

  const cellFlagMutation = useMutation(
    async () => {
      const response = await authenticatedFetch(
        "member/" +
        memberId +
        "/movement-matrix/item/" +
        cellValue?.category.id +
        "/" + (cellValue.isFlagged ? 'unflag' : 'flag'),
        'POST'
      );
      if (!response.ok) throw new Error();
      const result: {success?: boolean} = await response.json();
      return result.success || false;
    },
    {
      onSettled: () => {
        if (
            queryClient.isMutating({ mutationKey: "updateMovementMatrix" }) === 1
        ) {
          queryClient.invalidateQueries(["movement-matrix", memberId]);
        }
      },
      onSuccess: () => {toast.success(i18n['notifications.matrix.flagging.success'][!cellValue.isFlagged ? 'added' : 'removed'])},
      onError: () => {toast.error(i18n['notifications.on-error'])},
      retry: 3,
      mutationKey: "updateMovementMatrix",
    },
  );

  const getBackgroundColor = () => {
    if (
      cellValue.points === 3 ||
      cellValue.pointsLeft === 3 ||
      cellValue.pointsRight === 3
    )
      return "bg-red-600 bg-opacity-40";
    if (
      cellValue.points === 2 ||
      cellValue.pointsLeft === 2 ||
      cellValue.pointsRight === 2
    )
      return "bg-yellow-400 bg-opacity-40";
    if (
      cellValue.points === 1 ||
      cellValue.pointsLeft === 1 ||
      cellValue.pointsRight === 1
    )
      return "bg-green-600 bg-opacity-40";
  };

  return (
    <div
      className={twMerge(
        "flex flex-1 border-b-2 relative",
        getBackgroundColor(),
        className
      )}
    >
      {isLoading ? <div className="absolute top-0 bottom-0 left-0 right-0 h-full w-full bg-black/20 flex items-center justify-center">
        <Spinner className="w-6 h-6" />
      </div> : null}
      <div
        className={twMerge(
          "flex flex-1 items-center",
          isHalfSize && "flex-col"
        )}
      >
        <label
          className={twMerge(
            "flex py-4 w-32 text-sm text-brand-primary justify-center",
            isHalfSize && "pb-2",
            isHalfSize && showDoubleRow && "pt-1"
          )}
        >
          {label}
        </label>

        {showDoubleRow ? (
          <DoubleRadioRow
            cellValue={cellValue}
            updateCellValue={(props) => mutate(props)}
            className={radioRowClassName}
            name={label}
          />
        ) : (
          <SingleRadioRow
            cellValue={cellValue}
            updateCellValue={(props) => mutate(props)}
            className={radioRowClassName}
            name={label}
          />
        )}
      </div>
      <div className="flex flex-col">
        <button className="pt-1 pr-1 [&>*]:hover:text-red-500"
                onClick={() => cellFlagMutation.mutate()}
        >
          {cellValue.isFlagged ? <FaStar className="text-brand-red1" /> : <FaStar className="text-gray-300" />}
        </button>
        <button
          className="pt-2 pr-1 [&>*]:hover:text-brand-primary"
          onClick={() => setIsCellModalOpen(true)}
        >
          {cellValue.note ? <FaInfoCircle className="text-brand-primary" /> : <FaInfoCircle className="text-gray-300" />}
        </button>
      </div>

      {isCellModalOpen ? (
        <MovementMatrixCellModal
          memberId={memberId!}
          defaultValues={cellValue}
          label={label}
          closeModal={() => setIsCellModalOpen(false)}
        />
      ) : null}
    </div>
  );
};
