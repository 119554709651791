import {useI18n} from "../../translation";
import {HistoryListContainer} from "./components/HistoryList/HistoryListContainer";
import {CardTitle} from "./components/CardTitle";
import {HistoryListItem} from "./components/HistoryList/HistoryListItem";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {authenticatedFetch} from "../../service/api";
import {HealthscoreBaseData} from "../../types/HealthscoreData";
import {useState} from "react";
import {HealthScoreDetailModal} from "./HealthScoreDetailModal";

export const HealthScoreList = () => {
  const i18n = useI18n();
  const [modelData, setModelData] = useState<null|HealthscoreBaseData>(null);

  const { memberId } = useParams();
  const {data} = useQuery<{success: boolean, scores?: HealthscoreBaseData[]}>(
    ["healthscore", memberId],
    async () => {
      const response = await authenticatedFetch(
        "member/" + memberId + "/healthscore",
      );
      if (!response.ok) throw new Error();
      return response.json();
    }
  );

  return (
    <HistoryListContainer>
      <CardTitle title={i18n["memberDetails.healthscore.title"]} />
      <div className="flex flex-col mt-8 min-h-[114px] max-h-56 overflow-auto">
        {data?.scores?.map(score => {
          return <HistoryListItem key={score.id} date={score.finished} title={'Ergebnis: ' + score.score} onClick={() => setModelData(score)} />
        })}
      </div>
      {modelData !== null ? <HealthScoreDetailModal closeModal={() => setModelData(null)} data={modelData} label={'#' + modelData.id} /> : <></>}
    </HistoryListContainer>
  );
}
