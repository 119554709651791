export const DE_DE = {
  save: "Speichern",
  cancel: "Abbrechen",
  delete: "Löschen",
  date: "Datum",
  email: "E-Mail",
  chooseFile: "Datei auswählen",
  file: "Datei",
  type: "Typ",
  search: "Suche",
  "search.noResults": "Keine Ergebnisse gefunden",

  "home.preTitle": "Trainer-Dashboard",
  "home.welcomeBack": "Willkommen",
  "home.step1": "Wähle ein Unternehmen aus.",
  "home.step2a": "Suche einen Mitarbeiter, um zu starten.",
  "home.step2b": "Oder lege einen neuen Mitarbeiter an.",

  "actionHeader.addMember": "Mitarbeiter anlegen",
  "actionHeader.deleteMember": "Mitarbeiter löschen",

  "deleteMemberModal.title": "Mitarbeiter löschen",
  "deleteMemberModal.body": "Möchten Sie den Mitarbeiter wirklich löschen?",

  "memberModal.title": "Mitarbeiter anlegen",
  "memberModal.title.edit": "Mitarbeiter bearbeiten",
  "memberModal.firstName": "Vorname",
  "memberModal.lastName": "Nachname",
  "memberModal.gender": "Geschlecht",
  "memberModal.gender.placeholder": "Geschlecht auswählen...",
  "memberModal.gender.male": "Männlich",
  "memberModal.gender.female": "Weiblich",
  "memberModal.gender.diverse": "Divers",
  "memberModal.birthdate": "Geburtsdatum",
  "memberModal.phoneNumber": "Telefonnummer",
  "memberModal.mobeePersonId": "Mobee Personen ID",
  "memberModal.isTestUser": "Als Test-Nutzer markieren",
  "memberModal.company": "Unternehmen",
  "memberModal.company.placeholder": "Unternehmen auswählen...",
  "memberModal.addMemberToSitzkrieger.button": "Mitarbeiter zu Sitzkrieger hinzufügen",
  "memberModal.sitzkriegerHomework.button": "Sitzkrieger Hausaufgaben",
  "memberModal.sitzkriegerHomework.withConsentForHealthscore": "Alle Healthscores automatisch für Trainer freigeben",
  "memberModal.sitzkriegerHomework.videosInPlaylist": "Aktuell in der Playlist",
  "memberModal.sitzkriegerHomework.removeFromPlaylist": "Aus Playlist entfernen",
  "memberModal.sitzkriegerHomework.addToPlaylist": "Zur Playlist hinzufügen",
  "memberModal.sitzkriegerHomework.noVideosInPlaylist": "Keine Videos in der Playlist",

  "memberDetails.memberInfo.title": "Allgemeine Daten",
  "memberDetails.memberInfo.userName": `Benutzername:`,

  "memberDetails.memberInfo.email": "E-Mail",
  "memberDetails.memberInfo.age": "Alter",
  "memberDetails.memberInfo.testUser": "Test-Nutzer",
  "memberDetails.memberInfo.birthdate": "Geburtstag",
  "memberDetails.goals.title": "Ziele",
  "memberDetails.flags.title": "Red Flags",
  "memberDetails.goals.modal.goals.placeholder":
    "- Ziel 1\n\n- Ziel 2 \n\n- ...",
  "memberDetails.flags.modal.goals.placeholder": "",
  "memberDetails.movementMatrix.title": "Movement-Matrix",
  "memberDetails.movementMatrix.functional.short": "F",
  "memberDetails.movementMatrix.dysfunctional.short": "D",
  "memberDetails.movementMatrix.pain.short": "S",
  "memberDetails.movementMatrix.radioRow.left.short": "L:",
  "memberDetails.movementMatrix.radioRow.right.short": "R:",
  "memberDetails.movementMatrix.supinePattern": "Supine Pattern",
  "memberDetails.movementMatrix.hinge": "Hinge",
  "memberDetails.movementMatrix.rowing": "Rudern",
  "memberDetails.movementMatrix.supinePlank": "Supine Plank",
  "memberDetails.movementMatrix.mExtension": "M. Extension",
  "memberDetails.movementMatrix.shoulderBridge": "Schulterbrücke",
  "memberDetails.movementMatrix.pronePattern": "Prone Pattern",
  "memberDetails.movementMatrix.squat": "Kniebeuge",
  "memberDetails.movementMatrix.pushUp": "Liegestütz",
  "memberDetails.movementMatrix.pronePlank": "Prone Plank",
  "memberDetails.movementMatrix.mFlexion": "M. Flexion",
  "memberDetails.movementMatrix.quadrupedStand": "Vierfüßlerstand",
  "memberDetails.movementMatrix.sidelyingPattern": "Sidelying Pattern",
  "memberDetails.movementMatrix.standingBalance": "Standwaage",
  "memberDetails.movementMatrix.lunge": "Ausfallschritt",
  "memberDetails.movementMatrix.lateralSupport": "Seitstütz",
  "memberDetails.movementMatrix.mRotation": "M. Rotation",
  "memberDetails.movementMatrix.sideKickSeries": "Side Kick Serie",
  "memberDetails.movementMatrix.iad": "IAD/Atmung",
  "memberDetails.movementMatrix.hws": "HWS",
  "memberDetails.movementMatrix.controlPattern": "Dein Kontrollpattern:",
  "memberDetails.movementMatrix.controlPattern.title": "Kontrollpattern",
  "memberDetails.movementMatrix.controlPattern.label": "Kontrollpattern bearbeiten",
  "memberDetails.movementMatrix.cellModal.title": "Notizen für",
  "memberDetails.movementMatrix.cellModal.note": "Notiz",
  "memberDetails.trainingHistory.title": "Trainingsverlauf",
  "memberDetails.trainingHistory.modal.title": "Trainingsverlauf hinzufügen",
  "memberDetails.trainingHistory.modal.form.title": "Titel",
  "memberDetails.trainingHistory.modal.form.notes": "Notizen",
  "memberDetails.medicalHistory.title": "Anamnese + Sichtbefund",
  "memberDetails.testingArchive.title": "Testingarchiv",
  "memberDetails.testingArchive.options.startFMS": "FMS starten",
  "memberDetails.testingArchive.options.upload": "Hochladen",
  "memberDetails.testingArchive.fms.title": "FMS",
  "memberDetails.testingArchive.fms.clearing.short": "C",
  "memberDetails.testingArchive.fms.rating1.short": "1",
  "memberDetails.testingArchive.fms.rating2.short": "2",
  "memberDetails.testingArchive.fms.rating3.short": "3",
  "memberDetails.testingArchive.fms.radioColumn.left.short": "L",
  "memberDetails.testingArchive.fms.radioColumn.right.short": "R",
  "memberDetails.testingArchive.fms.comment": "Kommentar:",
  "memberDetails.testingArchive.fms.squat": "Kniebeuge",
  "memberDetails.testingArchive.fms.tspu": "TSPU",
  "memberDetails.testingArchive.fms.shoulderMobility": "Schultermobilität",
  "memberDetails.testingArchive.fms.wristMobility": "Handgelenksmobilität",
  "memberDetails.testingArchive.fms.aslr": "ASLR",
  "memberDetails.testingArchive.fms.ankleMobility": "Sprunggelenksmobilität",
  "memberDetails.testingArchive.fms.romberg": "Romberg",

  "memberDetails.testingArchive.upload.title": "Hochladen",
  "memberDetails.testingArchive.upload.type.placeholder": "Typ auswählen...",
  "memberDetails.testingArchive.upload.uploadButton.title": "Datei hochladen",
  "memberDetails.testingArchive.file.open": "Datei im neuen Tab öffnen",
  "memberDetails.healthscore.title": "Healthscore",

  "error.date": "Fehlerhaftes Datum",
  "error.noData": "Es konnten keine Daten abgerufen werden",

  "errorPage.title": "Seite nicht gefunden",
  "errorPage.subtitle": "Bitte überprüfe deine Angaben und versuche es erneut.",
  "errorPage.status": "404",
  "notifications.on-error": 'Fehler beim Aktualisieren aufgetreten - lade die Seite neu und probiere es erneut.',
  "notifications.matrix.controlpattern.success": 'Kontrollpattern erfolgreich gespeichert',
  "notifications.matrix.cell.success": 'Änderung erfolgreich gespeichert',
  "notifications.matrix.flagging.success": {
    'added': 'Erfolgreich markiert',
    'removed': 'Erfolgreich Markierung entfernt',
  },
  "notifications.fms.success": {
    "ADD": 'Hinzufügen des FMS Eintrags erfolgreich',
    "UPDATE": 'Aktualisierung des FMS Eintrags erfolgreich',
    "DELETE": 'Löschen des FMS Eintrags erfolgreich',
  },
  "notification.login-successful": "Login erfolgreich",
  "notification.login-unsuccessful": "Login fehlgeschlagen",
};
