import {HealthscoreBaseData} from "../../types/HealthscoreData";
import {Modal} from "../../components/Modal/Modal";
import {ModalHeader} from "../../components/Modal/ModalHeader";
import {ModalBody} from "../../components/Modal/ModalBody";

export const HealthScoreDetailModal = ({
  label,
  closeModal,
  data,
}: {
  label: string;
  closeModal: () => void;
  data: HealthscoreBaseData;
}) => {

  return (
    <Modal closeModal={closeModal}>
      <ModalHeader closeModal={closeModal} title={label} />
      <ModalBody>
        <div>
          <ul>
            {data.report.sectionScore.map((el) => {
              return <li key={el.id}><b>{el.label}: </b>{el.score}</li>
            })}
          </ul>
        </div>
        <br />
        <br />
        <div>
          {data.report.scoreData.map((el, i) => {
            return <div key={'answer-' + i}>
              <ul>
                <li>{el.question}</li>
                {el.hasColumns ? <li>
                  <ul>
                    {el.answers.map((a, n) => {
                      return <li key={'answer-item-' + n}><b>{a}</b> : {el.columns[n]}</li>
                    })}
                  </ul>
                </li> : <li><b>{el.answers[0] || ''}</b></li>}
                <li>Score: {el.score}</li>
              </ul>
              <br />
            </div>
          })}
        </div>
      </ModalBody>
    </Modal>
  );
}
