import { FaExternalLinkAlt, FaPen, FaTrash } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import { CircleButton } from "../../../../components/CircleButton";
import { ModalBody } from "../../../../components/Modal/ModalBody";
import { ModalHeader } from "../../../../components/Modal/ModalHeader";
import { Spinner } from "../../../../components/Spinner";
import { useI18n } from "../../../../translation";
import { TestingArchiveData } from "../../../../types/TestingArchiveData";
import { useDateUtils } from "../../../../utils/dateUtils";
import { FMSUpdateDataProps } from "./FmsModalContent";
import { useAuth } from "../../../layout/Authentication";

export const FmsModalViewContent = ({
  data,
  updateData,
  isUpdating,
  title,
  closeModal,
  switchMode,
}: {
  data: TestingArchiveData;
  updateData: (props: FMSUpdateDataProps) => void;
  isUpdating: boolean;
  title: string;
  closeModal: () => void;
  switchMode: () => void;
}) => {
  const i18n = useI18n();
  const auth = useAuth();
  const { getLocalizedDate } = useDateUtils();

  const headingClassName = "font-medium leading-tight text-lg mt-0";
  const descriptionClassName = "mt-1";

  const onDelete = () => {
    updateData({ data, type: "DELETE", onSuccessCB: closeModal });
  };

  return (
    <div>
      <ModalHeader title={title} closeModal={closeModal} />
      <ModalBody>
        {isUpdating ? <Spinner className="absolute inset-0 m-auto" /> : null}
        <div
          className={twMerge(
            "flex flex-col gap-y-6 pb-16",
            isUpdating && "opacity-60"
          )}
        >
          <div className="flex flex-1 justify-end gap-x-6">
            <CircleButton
              className="bg-brand-gray hover:bg-brand-red1 focus:bg-brand-red1 "
              onClick={onDelete}
              icon={<FaTrash />}
            />
            <CircleButton onClick={switchMode} icon={<FaPen />} />
          </div>
          <div>
            <p className={headingClassName}>{i18n["date"]}</p>
            <p className={descriptionClassName}>
              {getLocalizedDate(data.datetime)}
            </p>
          </div>
          <div>
            <p className={headingClassName}>{i18n["type"]}</p>
            <p className={descriptionClassName}>{data.type}</p>
          </div>
          <div>
            <p className={headingClassName}>{i18n["file"]}</p>
            <a
              href={data.fileLink + '?token=' + auth.token}
              target="_blank"
              rel="noopener noreferrer"
              className={"text-brand-primary flex flex-row " + descriptionClassName}
            >
              {i18n['memberDetails.testingArchive.file.open']} <span className={"px-1"}>
                <FaExternalLinkAlt /></span>
              </a>
          </div>
        </div>
      </ModalBody>
    </div>
  );
};
