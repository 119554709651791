import {Controller, useForm} from "react-hook-form";
import {MovementMatrixDataWithPattern} from "../../../../types/MovementMatrixData";
import {Textarea} from "../../../../components/form/Textarea";
import {Modal} from "../../../../components/Modal/Modal";
import {ModalBody} from "../../../../components/Modal/ModalBody";
import {ModalFooter} from "../../../../components/Modal/ModalFooter";
import {ModalFooterButtons} from "../../../../components/Modal/ModalFooterButtons";
import {ModalHeader} from "../../../../components/Modal/ModalHeader";
import {useI18n} from "../../../../translation";
import {useMutation, useQueryClient} from "react-query";
import {authenticatedFetch} from "../../../../service/api";
import {toast} from "react-hot-toast";

export const MovementMatrixControlPatternModal = ({
  memberId,
  defaultValues,
  closeModal,
}: {
  memberId: string,
  defaultValues: MovementMatrixDataWithPattern | undefined;
  closeModal: () => void;
}) => {
  const i18n = useI18n();

  const formMethods = useForm<{ controlPattern: string }>({defaultValues});
  const {handleSubmit} = formMethods;

  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(
    async (data: { controlPattern: string }) => {
      const response = await authenticatedFetch(
        "member/" +
        memberId +
        "/movement-matrix/control-pattern",
        'POST',
        data.controlPattern,
        true,
      );
      if (!response.ok) throw new Error();
      const result: { success?: boolean } = await response.json();
      return result.success || false;
    },
    {
      onSuccess: async () => {
        closeModal();
        await queryClient.invalidateQueries(["movement-matrix", memberId]);
        toast.success(i18n['notifications.matrix.controlpattern.success']);
      },
      onError: async () => {
        toast.error(i18n['notifications.on-error']);
      },
    }
  );

  const onSubmit = (data: { controlPattern: string }) => mutate(data);

  return (
    <Modal closeModal={closeModal}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          title={i18n["memberDetails.movementMatrix.controlPattern.title"]}
          closeModal={closeModal}
        />
        <ModalBody>
          <div className="flex flex-col gap-y-6">
            <Controller
              render={({field}) =>
                <Textarea field={field}
                          id={"memberDetails-movementMatrix-controlPattern"}
                          label={i18n["memberDetails.movementMatrix.controlPattern.label"]}
                          className={''}
                />
              }
              name="controlPattern"
              control={formMethods.control}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons cancel={closeModal} isSubmitLoading={isLoading}/>
        </ModalFooter>
      </form>
    </Modal>
  );
};
