import { useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import {
  TestingArchiveData,
  TestingArchiveFMSFormData,
} from "../../../../types/TestingArchiveData";
import { TestingArchiveModalFMSData } from "../TestingArchive";
import { FmsModalEditContent } from "./FmsModalEditContent";
import { FmsModalViewContent } from "./FmsModalViewContent";
import { authenticatedFetch } from "../../../../service/api";
import {toast} from 'react-hot-toast';
import {useI18n} from "../../../../translation";

export type FMSUpdateDataProps =
  | { onSuccessCB: () => void } & (
      | {
          data: TestingArchiveFMSFormData;
          type: "ADD";
        }
      | {
          data: TestingArchiveData;
          type: "UPDATE" | "DELETE";
        }
    );

export const FmsModalContent = ({
  isContentEditable,
  modalData,
  title,
  cancel,
  closeModal,
  switchMode,
}: {
  isContentEditable: boolean;
  modalData: TestingArchiveModalFMSData;
  title: string;
  cancel: () => void;
  closeModal: () => void;
  switchMode: () => void;
}) => {
  const i18n = useI18n();
  const { memberId } = useParams();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async ({ data, type }: FMSUpdateDataProps) => {

      let response = { ok: false } as Response;
      if (type === "ADD") {
        response = await authenticatedFetch(
          "member/" + memberId + "/testing-archive/fms",
            'PUT',
          {categoryRatings: data.categoryRatings},
        );
      }
      if (type === "UPDATE") {
        response = await authenticatedFetch(
          "member/" + memberId + "/testing-archive/fms/" + data.id,
            "POST",
          {categoryRatings: data.categoryRatings},
        );
      }
      if (type === "DELETE") {
        response = await authenticatedFetch("member/" + memberId + "/testing-archive/" + data.id, 'DELETE');
      }
      if (!response.ok) throw new Error();
      toast.success(i18n['notifications.fms.success'][type]);
      return response.json();
    },
    {
      onSuccess: async (data, { onSuccessCB }) => {
        await queryClient.invalidateQueries(["testingArchive", memberId]);
        await queryClient.invalidateQueries(["medicalHistory", memberId]);
        onSuccessCB();
      },
      onError: async () => {
        toast.success(i18n['notifications.on-error']);
      },
    }
  );

  return isContentEditable ? (
    <FmsModalEditContent
      modalData={modalData}
      updateData={(props) => mutate(props)}
      isUpdating={isLoading}
      title={title}
      closeModal={closeModal}
      cancel={cancel}
    />
  ) : (
    <FmsModalViewContent
      data={modalData.defaultValues as TestingArchiveData}
      updateData={(props) => mutate(props)}
      isUpdating={isLoading}
      title={title}
      closeModal={closeModal}
      switchMode={switchMode}
    />
  );
};
