import { useDateUtils } from "../../../../utils/dateUtils";

export const HistoryListItem = ({
  date,
  title,
  onClick,
}: {
  date: string;
  title: string;
  onClick: () => void;
}) => {
  const { getLocalizedDate } = useDateUtils();

  return (
    <button
      className="flex mb-2 border-b-2 pb-1 mr-2 [&>div]:hover:text-brand-primary"
      onClick={onClick}
    >
      <div className="text-brand-darkgray truncate">
        {getLocalizedDate(date) + " | " + title}
      </div>
    </button>
  );
};
