import { UseQueryResult } from "react-query";
import { Spinner } from "../../../../components/Spinner";
import { useI18n } from "../../../../translation";
import { HistoryListData } from "../../../../types/HistoryListData";
import { HistoryListItem } from "./HistoryListItem";

export const HistoryListContent = ({
  queryResult,
  openEntry,
}: {
  queryResult: UseQueryResult<HistoryListData[], unknown>;
  openEntry: (data: any) => void;
}) => {
  const i18n = useI18n();
  const { isLoading, isError, data } = queryResult;

  if (isLoading)
    return (
      <div className="flex flex-1 justify-center items-center my-2 min-h-[130px]">
        <Spinner />
      </div>
    );
  if (isError || !data || data.length < 1)
    return (
      <div className="flex flex-1 justify-center items-center my-2 text-gray-500 min-h-[130px]">
        {i18n["error.noData"]}
      </div>
    );

  return (
    <div className="flex flex-col mt-8 min-h-[114px] max-h-56 overflow-auto">
      {data.map((data) => (
        <HistoryListItem
          key={data.id}
          date={data.datetime}
          title={data.title}
          onClick={() => openEntry(data)}
        />
      ))}
    </div>
  );
};
