import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/Spinner";
import { useI18n } from "../../../translation";
import { TestingArchiveData } from "../../../types/TestingArchiveData";
import { HistoryListItem } from "../components/HistoryList/HistoryListItem";
import { authenticatedFetch } from "../../../service/api";

export const TestingArchiveContent = ({
  openEntry,
}: {
  openEntry: (data: TestingArchiveData) => void;
}) => {
  const i18n = useI18n();
  const { memberId } = useParams();
  const { isLoading, isError, data } = useQuery<TestingArchiveData[]>(
    ["testingArchive", memberId],
    async () => {
      const response = await authenticatedFetch(
        "member/" + memberId + "/testing-archive",
      );
      if (!response.ok) throw new Error();
      return response.json();
    }
  );

  if (isLoading)
    return (
      <div className="flex flex-1 justify-center items-center my-2 min-h-[130px]">
        <Spinner />
      </div>
    );
  if (isError || !data || data.length < 1)
    return (
      <div className="flex flex-1 justify-center items-center my-2 text-gray-500 min-h-[130px]">
        {i18n["error.noData"]}
      </div>
    );

  return (
    <div className="flex flex-col mt-8 min-h-[114px] max-h-56 overflow-auto">
      {data.map((data) => (
        <HistoryListItem
          key={data.id}
          date={data.datetime}
          title={data.type}
          onClick={() => openEntry(data)}
        />
      ))}
    </div>
  );
};
