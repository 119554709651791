import { MovementMatrixData } from "../../../types/MovementMatrixData";
import { useI18n } from "../../../translation";
import { MovementMatrixCell } from "./components/MovementMatrixCell";

export const SupinePattern = ({ data, fullScreen }: { data: MovementMatrixData, fullScreen: boolean }) => {
  const i18n = useI18n();

  return (
    <div className={'flex flex-1 flex-col  ' + (fullScreen ? 'lg:border-r-2' : 'xl:border-r-2')}>
      <div className="py-4 flex justify-center text-brand-primary font-semibold border-b-2">
        {i18n["memberDetails.movementMatrix.supinePattern"]}
      </div>
      <div className={'flex flex-col ' + (fullScreen ? 'md:max-lg:flex-row' : 'md:max-xl:flex-row')}>
        <MovementMatrixCell
          cellValue={data?.["hinge"]}
          className={(fullScreen ? 'md:max-lg:border-r-2' : 'md:max-xl:border-r-2')}
          label={i18n["memberDetails.movementMatrix.hinge"]}
        />
        <MovementMatrixCell
          cellValue={data?.["rudern"]}
          label={i18n["memberDetails.movementMatrix.rowing"]}
        />
      </div>

      <div className="flex">
        <MovementMatrixCell
          cellValue={data?.["supine-plank"]}
          className="border-r-2"
          isHalfSize
          label={i18n["memberDetails.movementMatrix.supinePlank"]}
        />
        <MovementMatrixCell
          cellValue={data?.["m-extension"]}
          isHalfSize
          label={i18n["memberDetails.movementMatrix.mExtension"]}
        />
      </div>

      <MovementMatrixCell
        cellValue={data?.["schulterbruecke"]}
        label={i18n["memberDetails.movementMatrix.shoulderBridge"]}
      />
    </div>
  );
};
