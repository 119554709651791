import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { MemberData } from "../../types/MemberData";
import { Spinner } from "../Spinner";
import { useI18n } from "../../translation";
import { useDateUtils } from "../../utils/dateUtils";
import { authenticatedFetch } from "../../service/api";

const SearchResults = ({
  projectId,
  searchText,
}: {
  projectId: string;
  searchText: string;
}) => {
  const i18n = useI18n();
  const { getLocalizedDate } = useDateUtils();
  const { data, isLoading } = useQuery<MemberData[]>(
    ["members", projectId, searchText],
    async () => {
      const response = await authenticatedFetch("member?" +
          new URLSearchParams({
              search: searchText,
              projectId,
          }));
      if (!response.ok) throw new Error();
      const { items } = await response.json();
      return items;
    }
  );
  const membersFound = data && data.length > 0;

  if (isLoading) return <Spinner className="self-center w-8 h-8 my-4" />;
  if (!membersFound || typeof data === 'undefined')
    return (
      <p className="self-center text-gray-500 py-5">
        {i18n["search.noResults"]}
      </p>
    );
  return (
    <>
      {data.map(({ id, info }) => (
        <Link to={`/members/${id}`} key={id}>
          <div className="hover:bg-gray-100 p-2">
            <p className="text-lg font-medium">
              {info.firstName + " " + info.lastName}
            </p>
            <p className="text-gray-500">
              {getLocalizedDate(info.birthday) + " - " + info.email}
            </p>
          </div>
        </Link>
      ))}
    </>
  );
};

export const MemberSearchResults = ({
  projectId,
  searchText,
  className,
}: {
  projectId: string;
  searchText: string;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        "flex flex-col mt-2 gap-2 p-2 bg-white border-2 rounded shadow-md w-full",
        className
      )}
    >
      <SearchResults projectId={projectId} searchText={searchText} />
    </div>
  );
};
