import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const ModalBody = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <div className={twMerge("modal-body relative p-4", className)}>
      {children}
    </div>
  );
};
