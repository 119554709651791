import { forwardRef } from "react";

type DatePickerProps = {
  label: string;
};

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, ref) => {
    const { label, ...otherProps } = props;

    return (
      <div className="datepicker relative form-floating">
        <input
          ref={ref}
          type="date"
          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          placeholder={label}
          id={label}
          {...otherProps}
        />
        <label htmlFor={label} className="text-gray-700">
          {label}
        </label>
      </div>
    );
  }
);
