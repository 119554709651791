import { twMerge } from "tailwind-merge";
import { RadioInput } from "../../../../components/form/RadioInput";
import { useI18n } from "../../../../translation";
import { MovementMatrixCellValue } from "../../../../types/MovementMatrixData";
import { UpdateCellValueProps } from "./MovementMatrixCell";

export const SingleRadioRow = ({
  cellValue,
  updateCellValue,
  name,
  className,
}: {
  cellValue: MovementMatrixCellValue;
  updateCellValue: (props: UpdateCellValueProps) => void;
  name: string;
  className?: string;
}) => {
  const i18n = useI18n();

  const getSelectedValue = () => cellValue?.points ?? 0;

  return (
    <div className={twMerge("flex flex-1", className)}>
      <span className="w-5" />
      <RadioInput
        name={name}
        value={1}
        selectedValue={getSelectedValue()}
        setSelectedValue={(newValue) =>
          updateCellValue({ newValue: newValue as 1 })
        }
        onResetAction={() => updateCellValue({newValue: 0})}
        className="[&>label]:text-green-600"
        label={i18n["memberDetails.movementMatrix.functional.short"]}
      />
      <RadioInput
        name={name}
        value={2}
        selectedValue={getSelectedValue()}
        setSelectedValue={(newValue) =>
          updateCellValue({ newValue: newValue as 2 })
        }
        onResetAction={() => updateCellValue({newValue: 0})}
        className="[&>label]:text-yellow-400"
        label={i18n["memberDetails.movementMatrix.dysfunctional.short"]}
      />
      <RadioInput
        name={name}
        value={3}
        selectedValue={getSelectedValue()}
        setSelectedValue={(newValue) =>
          updateCellValue({ newValue: newValue as 3 })
        }
        onResetAction={() => updateCellValue({newValue: 0})}
        className="[&>label]:text-red-600"
        label={i18n["memberDetails.movementMatrix.pain.short"]}
      />
    </div>
  );
};
