import { useState } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../../../translation";
import {MovementMatrixControlPatternModal} from "./MovementMatrixControlPatternModal";
import {MovementMatrixDataWithPattern} from "../../../../types/MovementMatrixData";
import {FaPen} from "react-icons/fa";
import {CircleButton} from "../../../../components/CircleButton";
import parse from "html-react-parser";

export const ControlPattern = ({ data }: { data: MovementMatrixDataWithPattern }) => {
  const i18n = useI18n();
  const [isControlPatternModalOpen, setIsControlPatternModalOpen] = useState(false);

  const { memberId } = useParams();

  return (
    <>
      <div className="flex p-4 gap-4 whitespace-nowrap items-center">
        <h4 className={'text-brand-primary font-semibold'}>{i18n["memberDetails.movementMatrix.controlPattern"]}</h4>
        {isControlPatternModalOpen ?
          <MovementMatrixControlPatternModal
            memberId={memberId!}
            defaultValues={data}
            closeModal={() => setIsControlPatternModalOpen(false)}
          />: null}
        <CircleButton className="ml-auto" onClick={() => setIsControlPatternModalOpen(true)} icon={<FaPen />} />
      </div>
      <div className="p-4 pt-0 prose">{parse(data.controlPattern || '')}</div>
    </>
  );
};
