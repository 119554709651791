import { twMerge } from "tailwind-merge";
import { RadioInput } from "../../../../components/form/RadioInput";
import { useI18n } from "../../../../translation";
import { MovementMatrixCellValue } from "../../../../types/MovementMatrixData";
import { UpdateCellValueProps } from "./MovementMatrixCell";

export const DoubleRadioRow = ({
  cellValue,
  updateCellValue,
  name,
  className,
}: {
  cellValue: MovementMatrixCellValue;
  updateCellValue: (props: UpdateCellValueProps) => void;
  name: string;
  className?: string;
}) => {
  const i18n = useI18n();

  const rows = [
    {
      title: i18n["memberDetails.movementMatrix.radioRow.left.short"],
      isLeft: true,
    },
    {
      title: i18n["memberDetails.movementMatrix.radioRow.right.short"],
      isLeft: false,
    },
  ];

  const getSelectedValue = (isLeft: boolean) =>
    (isLeft ? cellValue?.pointsLeft : cellValue?.pointsRight) ?? 0;

  return (
    <div className={twMerge("flex flex-1 flex-col", className)}>
      {rows.map(({ title, isLeft }) => (
        <div key={title} className="flex">
          <span className="self-center w-5 text-sm text-brand-primary">
            {title}
          </span>
          <RadioInput
            name={title + name}
            value={1}
            selectedValue={getSelectedValue(isLeft)}
            setSelectedValue={(newValue) =>
              updateCellValue({ newValue: newValue as 1, isLeft })
            }
            onResetAction={() => updateCellValue({newValue: 0, isLeft})}
            className="[&>label]:text-green-600"
            label={i18n["memberDetails.movementMatrix.functional.short"]}
          />
          <RadioInput
            name={title + name}
            value={2}
            selectedValue={getSelectedValue(isLeft)}
            setSelectedValue={(newValue) =>
              updateCellValue({ newValue: newValue as 2, isLeft })
            }
            onResetAction={() => updateCellValue({newValue: 0, isLeft})}
            className="[&>label]:text-yellow-400"
            label={i18n["memberDetails.movementMatrix.dysfunctional.short"]}
          />
          <RadioInput
            name={title + name}
            value={3}
            selectedValue={getSelectedValue(isLeft)}
            setSelectedValue={(newValue) =>
              updateCellValue({ newValue: newValue as 3, isLeft: isLeft })
            }
            onResetAction={() => updateCellValue({newValue: 0, isLeft})}
            className="[&>label]:text-red-600"
            label={i18n["memberDetails.movementMatrix.pain.short"]}
          />
        </div>
      ))}
    </div>
  );
};
