import {Controller, useForm} from "react-hook-form";
import { MovementMatrixCellValue } from "../../../../types/MovementMatrixData";
import { Textarea } from "../../../../components/form/Textarea";
import { Modal } from "../../../../components/Modal/Modal";
import { ModalBody } from "../../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../../components/Modal/ModalFooter";
import { ModalFooterButtons } from "../../../../components/Modal/ModalFooterButtons";
import { ModalHeader } from "../../../../components/Modal/ModalHeader";
import { useI18n } from "../../../../translation";
import { useMutation, useQueryClient } from "react-query";
import { authenticatedFetch } from "../../../../service/api";

export const MovementMatrixCellModal = ({
  memberId,
  defaultValues,
  label,
  closeModal,
}: {
  memberId: string,
  defaultValues: MovementMatrixCellValue | undefined;
  label: string;
  closeModal: () => void;
}) => {
  const i18n = useI18n();

  const formMethods = useForm<{ note: string }>({ defaultValues });
  const { handleSubmit } = formMethods;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async (data: { note: string }) => {
      const response = await authenticatedFetch(
        "member/" +
        memberId +
        "/movement-matrix/item/" +
        defaultValues?.category.id +
        "/comment",
        'POST',
        data.note,
        true,
      );
      if (!response.ok) throw new Error();
      const result: {success?: boolean} = await response.json();
      return result.success || false;
    },
    {
      onSuccess: async () => {
        closeModal();
        await queryClient.invalidateQueries(["movement-matrix", memberId]);
      },
      onError: async () => {},
    }
  );

  const onSubmit = (data: { note: string }) => mutate(data);

  return (
    <Modal closeModal={closeModal}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          title={i18n["memberDetails.movementMatrix.cellModal.title"] + " " + label}
          closeModal={closeModal}
        />
        <ModalBody>
          <div className="flex flex-col gap-y-6">
            <Controller
              render={({field}) =>
                <Textarea field={field}
                          id={'memberDetails-movementMatrix-cellModal-note-' + defaultValues!.category.id}
                          label={i18n["memberDetails.movementMatrix.cellModal.note"]}
                          className={''}
                />
              }
              name="note"
              control={formMethods.control}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons cancel={closeModal} isSubmitLoading={isLoading} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
