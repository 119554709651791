import { MovementMatrixData } from "../../../types/MovementMatrixData";
import { useI18n } from "../../../translation";
import { MovementMatrixCell } from "./components/MovementMatrixCell";

export const SidelyingPattern = ({ data, fullScreen }: { data: MovementMatrixData, fullScreen: boolean }) => {
  const i18n = useI18n();

  return (
    <div className="flex flex-1 flex-col">
      <div className="py-4 flex justify-center text-brand-primary font-semibold border-b-2">
        {i18n["memberDetails.movementMatrix.sidelyingPattern"]}
      </div>
      <div className={'flex flex-col ' + (fullScreen ? 'md:max-lg:flex-row' : 'md:max-xl:flex-row')}>
        <MovementMatrixCell
          cellValue={data["standwaage"]}
          showDoubleRow
          className={(fullScreen ? 'md:max-lg:border-r-2' : 'md:max-xl:border-r-2')}
          label={i18n["memberDetails.movementMatrix.standingBalance"]}
        />
        <MovementMatrixCell
          cellValue={data["ausfallschritt"]}
          showDoubleRow
          label={i18n["memberDetails.movementMatrix.lunge"]}
        />
      </div>

      <div className="flex">
        <MovementMatrixCell
          cellValue={data["seitstuetz"]}
          showDoubleRow
          isHalfSize
          className="border-r-2"
          label={i18n["memberDetails.movementMatrix.lateralSupport"]}
        />
        <MovementMatrixCell
          cellValue={data["m-rotation"]}
          showDoubleRow
          isHalfSize
          label={i18n["memberDetails.movementMatrix.mRotation"]}
        />
      </div>

      <MovementMatrixCell
        cellValue={data["side-kick-serie"]}
        showDoubleRow
        label={i18n["memberDetails.movementMatrix.sideKickSeries"]}
      />
    </div>
  );
};
