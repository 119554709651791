import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { MemberData } from "../../../types/MemberData";
import { CircleButton } from "../../../components/CircleButton";
import { useI18n } from "../../../translation";
import { MemberSearchResults } from "../../../components/search/MemberSearchResults";
import { DeleteMemberModal } from "./DeleteMemberModal";
import { SearchBar } from "../../../components/search/SearchBar";
import {AddMember} from "../components/AddMember";

export const ActionHeader = ({ data }: { data: MemberData }) => {
  const i18n = useI18n();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");

  return (
    <div className="flex flex-col gap-y-4 md:flex-row">
      <div className="flex flex-1 gap-x-8 items-center">
        <AddMember projectId={data.projectId} />

        <div className="flex gap-x-3 items-center">
          <CircleButton
            className="bg-brand-gray hover:bg-brand-red1 focus:bg-brand-red1
           active:bg-brand-red0"
            onClick={() => setIsDeleteModalOpen(true)}
            icon={<FaTrash />}
          />
          <p>{i18n["actionHeader.deleteMember"]}</p>
        </div>
      </div>

      <SearchBar searchText={searchText} setSearchText={setSearchText}>
        <MemberSearchResults
          className="absolute top-9 right-0 md:w-[50vw] lg:w-[35vw] max-h-[75vh] overflow-auto"
          searchText={searchText}
          projectId={data.projectId}
        />
      </SearchBar>

      {isDeleteModalOpen ? (
        <DeleteMemberModal
          data={data}
          label={i18n["deleteMemberModal.title"]}
          closeModal={() => setIsDeleteModalOpen(false)}
        />
      ) : null}
    </div>
  );
};
