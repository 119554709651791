import {Controller, useForm} from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { MemberData } from "../../../types/MemberData";
import { Textarea } from "../../../components/form/Textarea";
import { Modal } from "../../../components/Modal/Modal";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../components/Modal/ModalFooter";
import { ModalFooterButtons } from "../../../components/Modal/ModalFooterButtons";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { useI18n } from "../../../translation";
import { authenticatedFetch } from "../../../service/api";
import {MultiInputEdit} from "../../../components/form/MultiInputEdit";

export const GoalsModal = ({
  label,
  defaultValues,
  closeModal,
  useMultiTextInput,
}: {
  label: string;
  defaultValues: MemberData;
  closeModal: () => void;
  useMultiTextInput: boolean;
}) => {
  const i18n = useI18n();

  const formMethods = useForm<MemberData>({ defaultValues });
  const { handleSubmit } = formMethods;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async ({ id, goal }: MemberData) => {
      const response = await authenticatedFetch("member/" + id, 'POST', { goal });
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      onSuccess: async () => {
        closeModal();
        await queryClient.invalidateQueries(["member", defaultValues.id]);
      },
      onError: async () => {},
    }
  );

  const onSubmit = (data: MemberData) => mutate(data);

  return (
    <Modal closeModal={closeModal}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader title={label} closeModal={closeModal} />
        <ModalBody>
          <div className="flex flex-col gap-y-6">
            <Controller
              render={({field}) => {
                if (useMultiTextInput) {
                  return <MultiInputEdit field={field} />;
                }
                return <Textarea field={field}
                          id='memberDetails-goals-modal-goals'
                          label={i18n['memberDetails.goals.modal.goals.placeholder']}
                          className={'h-44'}
                />;
              }
            }
              name="goal"
              control={formMethods.control}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons cancel={closeModal} isSubmitLoading={isLoading} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
