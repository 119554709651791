import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { MemberData } from "../../../types/MemberData";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal/Modal";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../components/Modal/ModalFooter";
import { ModalHeader } from "../../../components/Modal/ModalHeader";
import { useI18n } from "../../../translation";
import { authenticatedFetch } from "../../../service/api";

export const DeleteMemberModal = ({
  data,
  label,
  closeModal,
}: {
  data: MemberData;
  label: string;
  closeModal: () => void;
}) => {
  const i18n = useI18n();

  const navigate = useNavigate();
  const deleteMutation = useMutation(
    async () => {
      const response = await authenticatedFetch("member/" + data.id, 'DELETE');
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      onSuccess: async () => {
        closeModal();
        navigate("/");
      },
      onError: async () => {},
    }
  );

  return (
    <Modal closeModal={closeModal}>
      <ModalHeader title={label} closeModal={closeModal} />
      <ModalBody>
        <p className="py-4">{i18n["deleteMemberModal.body"]}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bg-brand-gray hover:bg-yellow-500 focus:bg-yellow-500
           active:bg-yellow-400"
          title={i18n["cancel"]}
          onClick={closeModal}
          isDisabled={deleteMutation.isLoading}
        />
        <Button
          className="bg-red-600 hover:bg-brand-red1 focus:bg-brand-red1
           active:bg-brand-red0"
          title={i18n["delete"]}
          onClick={() => deleteMutation.mutate()}
          isLoading={deleteMutation.isLoading}
        />
      </ModalFooter>
    </Modal>
  );
};
