import {ReactElement, useEffect, useState} from "react";
import {CircleButton} from "../CircleButton";
import {FaTrash} from "react-icons/fa";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";

export const MultiInputView = ({json, renderListIcon = null}: {json: {items: string[]}, renderListIcon?: JSX.Element | null}) => {
  const hasListIcon = renderListIcon !== null;
  return <ul className={hasListIcon ? "list-none pl-0" : ''}>
    {json.items.map((item, index) => {
      return <li key={index}><span className="flex flex-row">{renderListIcon}{item}</span></li>
    })}
  </ul>
}

export const MultiInputEdit = ({field}: { field: ControllerRenderProps<any, any> }): ReactElement => {
  const data = (field.value || '') !== '' ? JSON.parse(field.value || '') : '';
  const [items, setItems] = useState<string[]>(typeof data === 'object' && data.hasOwnProperty('items') ? data.items : []);

  useEffect(() => {
    field.onChange({target: {value: JSON.stringify({items})}});
  }, [items, field]);

  return <>
    {items.map((item: string, index: number) => {
      return <div className="flex w-full" key={index}>
        <input className="form-control
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
               value={item} onChange={(event) => {
          const newItems = [];
          for (let itemIndex in items) {
            if (Number(itemIndex) === index) {
              newItems.push(event.target.value);
            } else {
              newItems.push(items[itemIndex]);
            }
          }
          setItems(newItems);
        }} type='text'/>
        <CircleButton
          className="bg-brand-gray hover:bg-brand-red1 focus:bg-brand-red1
           active:bg-brand-red0 ml-4"
          onClick={() => {
            const newItems = [];
            for (let itemIndex in items) {
              if (Number(itemIndex) !== index) {
                newItems.push(items[itemIndex]);
              }
            }
            setItems(newItems);
          }}
          icon={<FaTrash />}
        />
      </div>
    })}
    <CircleButton onClick={() => {
      const newItems = [];
      for (let itemIndex in items) {
        newItems.push(items[itemIndex]);
      }
      newItems.push('');
      setItems(newItems);
    }}/>
  </>;
};
