import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Button } from "../../../../components/Button";
import { DatePicker } from "../../../../components/form/DatePicker";
import { FileInput } from "../../../../components/form/FileInput";
import { Select } from "../../../../components/form/Select";
import { ModalBody } from "../../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../../components/Modal/ModalFooter";
import { ModalFooterButtons } from "../../../../components/Modal/ModalFooterButtons";
import { ModalHeader } from "../../../../components/Modal/ModalHeader";
import { useI18n } from "../../../../translation";
import { SelectOptions } from "../../../../types/SelectOptions";
import { TestingArchiveCategoryData } from "../../../../types/TestingArchiveCategoryData";
import {
  TestingArchiveData,
  TestingArchiveFileFormData,
} from "../../../../types/TestingArchiveData";
import { TestingArchiveModalFileData } from "../TestingArchive";
import { FileUpdateDataProps } from "./UploadModalContent";
import { authenticatedFetch } from "../../../../service/api";

export const UploadModalEditContent = ({
  modalData,
  updateData,
  isUpdating,
  title,
  closeModal,
  cancel,
}: {
  modalData: TestingArchiveModalFileData;
  updateData: (props: FileUpdateDataProps) => void;
  isUpdating: boolean;
  title: string;
  closeModal: () => void;
  cancel: () => void;
}) => {
  const i18n = useI18n();
  const { defaultValues, isNewEntry } = modalData;
  const formMethods = useForm<TestingArchiveModalFileData["defaultValues"]>({
    defaultValues,
  });
  const { register, handleSubmit, watch, setValue, control } = formMethods;
  const onSubmit = (data: TestingArchiveModalFileData["defaultValues"]) => {
    if (isNewEntry)
      updateData({
        data: data as TestingArchiveFileFormData,
        type: "ADD",
        onSuccessCB: closeModal,
      });
    else
      updateData({
        data: data as TestingArchiveData,
        type: "UPDATE",
        onSuccessCB: closeModal,
      });
  };

  const { data: categoryOptions } = useQuery<SelectOptions[]>(
    ["testingArchive", "categories"],
    async () => {
      const response = await authenticatedFetch("categories/testing-archive");
      if (!response.ok) throw new Error();
      const categories: TestingArchiveCategoryData[] = await response.json();
      return categories.map(({ key, label }) => ({ value: key, label }));
    }
  );
  const allCategoryOptions = [
    {
      value: "none",
      label: i18n["memberDetails.testingArchive.upload.type.placeholder"],
    },
    ...(categoryOptions || []),
  ];

  const watchedFileId = watch("fileId");
  // caution! Hacky double use as string & FileList
  const watchedFileLink = watch("fileLink");
  const { mutate, isLoading: isLoadingFile } = useMutation(async () => {
    const formData = new FormData();
    formData.set("file", watchedFileLink[0]);

    const response = await authenticatedFetch("upload", 'POST', formData, true);
    if (!response.ok) throw new Error();
    const { id } = await response.json();
    setValue("fileId", id);
    return id;
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader title={title} closeModal={closeModal} />
      <ModalBody>
        <div className="flex flex-col gap-y-6">
          <DatePicker
            label={i18n["date"]}
            {...register("datetime", { required: true })}
          />

          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select controllerProps={field} options={allCategoryOptions} />
            )}
            rules={{ validate: (value) => !!value && value !== "none" }}
          />

          <FileInput {...register("fileLink", { required: true })} />
          {watchedFileLink?.length > 0 && (
            <Button
              isLoading={isLoadingFile}
              onClick={mutate}
              title={
                i18n["memberDetails.testingArchive.upload.uploadButton.title"]
              }
              {...register("fileId", { required: true })}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons
          cancel={cancel}
          disabled={!watchedFileId}
          isSubmitLoading={isUpdating}
        />
      </ModalFooter>
    </form>
  );
};
