import {API_URL, BASE_URL} from "./config";
import jwt_decode from "jwt-decode";
import {TrainerData} from "../types/TrainerData";

export const getToken = (): string | null => {
  return localStorage.getItem('sitzkrieger-token') || null;
}

export async function login(username: string, password: string): Promise<string> {
  let formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  const response = await fetch(`${BASE_URL}login`, {
    method: 'POST',
    body: formData,
  });
  if (response.status === 403) {
    return '';
  }
  const json = await response.json();
  return json.token;
}

export async function isAuthenticated(token: string): Promise<boolean> {
  const init: RequestInit = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }),
  };
  const response = await fetch(`${BASE_URL}authenticated`, init);
  if (!response.ok) {
    return false;
  }
  const json = await response.json();
  const success = json.success === true;
  if (success && json.hasOwnProperty('token')) {
    localStorage.setItem('sitzkrieger-token', json.token);
    return true;
  }
  return success;
}

export async function getTrainerData(token: string): Promise<TrainerData> {
  const res = await authenticatedFetchWithToken(token, 'trainer/me');
  const data = await res.json();
  return data satisfies TrainerData;
}

export async function authenticatedFetch(path: string, method: string = 'GET', body?: any, noJson: boolean = false): Promise<Response> {
  const token = getToken();
  if (!token) {
    return {ok: false} as Response;
  }

  const refreshToken = async (): Promise<string|Response> => {
    const authenticated = await isAuthenticated(token);
    if (!authenticated) {
      return {ok: false} as Response;
    }
    const refreshedToken = getToken();
    if (!refreshedToken) {
      return {ok: false} as Response;
    }
    return refreshedToken;
  };

  const decodedToken: {exp: number, iat: number, roles: string[], username: string}|null = jwt_decode(token);
  if (decodedToken && (decodedToken.exp) - Math.floor((Date.now()) / 1000) <= 0) {
    const refreshedToken = await refreshToken();
    if (typeof refreshedToken !== 'string') {
      return refreshedToken;
    }
  }
  const result = await authenticatedFetchWithToken(token, path, method, body, noJson);
  if (!result.ok && result.status === 401) {
    const refreshedToken = await refreshToken();
    if (typeof refreshedToken === 'string') {
      return await authenticatedFetchWithToken(refreshedToken, path, method, body, noJson);
    }
    return refreshedToken;
  }
  return result;
}

async function authenticatedFetchWithToken(token: string, path: string, method: string = 'GET', body?: any, noJson: boolean = false): Promise<Response> {
  const init: RequestInit = {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }),
    method,
  };
  if (method !== 'GET' && typeof body !== 'undefined') {
    init.body = noJson ? body : JSON.stringify(body);
  }
  return await fetch(`${API_URL}${path}`, init);
}
