import {login} from "../service/api";
import {toast} from "react-hot-toast";
import {DE_DE} from "../translation/de";

export const sitzkriegerAuthProvider = {
  isAuthenticated: false,
  signin(username: string, password: string, callback: (token: string) => void) {
    login(username, password).then(token => {
      if (token) {
        localStorage.setItem('sitzkrieger-token', token);
        sitzkriegerAuthProvider.isAuthenticated = true;
        toast.success(DE_DE['notification.login-successful']);
        callback(token);
      } else {
        toast.error(DE_DE['notification.login-unsuccessful']);
      }
    });
  },
  signout(callback: VoidFunction) {
    sitzkriegerAuthProvider.isAuthenticated = false;
    localStorage.removeItem('sitzkrieger-token');
    callback();
  }
};
