import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from "react";

type FileInputProps = {
  label?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (props, ref) => {
    const { type, label, ...otherProps } = props;

    return (
      <div>
        {label ? (
          <label
            htmlFor={label}
            className="form-label inline-block mb-2 text-gray-700"
          >
            {label}
          </label>
        ) : null}
        <input
          ref={ref}
          type="file"
          className="form-control
        block
        w-full
        px-3
        py-1.5
        file:py-4
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id={label}
          placeholder={label}
          {...otherProps}
        />
      </div>
    );
  }
);
