type ValueOf<T> = T[keyof T];

export const MOVEMENT_MATRIX_CELLS = {
  schulterbruecke: "Schulterbrücke",
  "iad-atmung": "IAD/Atmung",
  hws: "HWS",
  "prone-plank": "Prone Plank",
  "m-flexion": "M. Flexion",
  kniebeuge: "Kniebeuge",
  standwaage: "Standwaage",
  vierfuesslerstand: "Vierfüßlerstand",
  rudern: "Rudern",
  "supine-plank": "Supine Plank",
  "side-kick-serie": "Side Kick Serie",
  liegestuetze: "Liegestütz",
  "m-rotation": "M. Rotation",
  ausfallschritt: "Ausfallschritt",
  "m-extension": "M. Extension",
  hinge: "Hinge",
  seitstuetz: "Seitstütz",
} as const;

type MOVEMENT_MATRIX_CELLS_TYPE = {
  -readonly [K in keyof typeof MOVEMENT_MATRIX_CELLS]: typeof MOVEMENT_MATRIX_CELLS[K];
};

export type MovementMatrixData = {
  [K in keyof MOVEMENT_MATRIX_CELLS_TYPE]: {
    category: {
      id: string;
      label: MOVEMENT_MATRIX_CELLS_TYPE[K];
      key: K;
    };
    note: string;
    isFlagged: boolean;
    isLeftRight: boolean;
    points?: 1 | 2 | 3;
    pointsLeft?: 1 | 2 | 3;
    pointsRight?: 1 | 2 | 3;
  };
};

export type MovementMatrixDataWithPattern = MovementMatrixData & {controlPattern?: string};

export type MovementMatrixCellValue = ValueOf<MovementMatrixData>;
