import {twMerge} from "tailwind-merge";

export const RadioInput = ({
  value,
  selectedValue,
  setSelectedValue,
  onResetAction,
  label,
  name,
  className,
}: {
  value: number;
  selectedValue: number;
  setSelectedValue: (newValue: number) => void;
  onResetAction?: () => void;
  label: string;
  name?: string;
  className?: string;
}) => {
  const isChecked = value === selectedValue;

  const updateWithResetCheck = (value: number) => {
    if (isChecked && onResetAction) {
      onResetAction();
      return;
    }
    if (!isChecked) {
      setSelectedValue(value);
    }
  }

  return (
    <div className={twMerge("flex flex-1 justify-center", className)}>
      <label
        className="form-check-label text-gray-800 mr-2"
        htmlFor={name + label}
      >
        {label}
      </label>
      <input
        className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
        type="radio"
        value={value}
        checked={isChecked}
        onClick={() => isChecked ? updateWithResetCheck(Number(value)) : null}
        onChange={(event) => updateWithResetCheck(Number(event.target.value))}
        id={name + label}
      />
    </div>
  );
};
