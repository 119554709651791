import { ReactNode } from "react";
import { FaPlus } from "react-icons/fa";
import { twMerge } from "tailwind-merge";

export const CircleButton = ({
  onClick,
  icon,
  className,
}: {
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={twMerge(
        "flex justify-center items-center rounded-full bg-brand-primary text-white leading-normal uppercase shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-9 h-9 flex-shrink-0",
        className
      )}
    >
      {icon ? icon : <FaPlus />}
    </button>
  );
};
