import { useI18n } from "../../translation";
import { Button } from "../Button";

export const ModalFooterButtons = ({
  cancel,
  onDelete,
  isSubmitLoading,
  isDeleteLoading,
  disabled,
}: {
  cancel: () => void;
  onDelete?: () => void;
  isSubmitLoading?: boolean;
  isDeleteLoading?: boolean;
  disabled?: boolean;
}) => {
  const i18n = useI18n();
  const isDisabled = isSubmitLoading || isDeleteLoading || disabled;

  return (
    <>
      <div className="flex flex-1">
        {onDelete ? (
          <Button
            title={i18n["delete"]}
            onClick={onDelete}
            className="bg-brand-gray hover:bg-brand-red1 focus:bg-brand-red1 
           active:bg-brand-red0"
            isDisabled={isDisabled}
            isLoading={isDeleteLoading}
          />
        ) : null}
      </div>
      <Button
        title={i18n["cancel"]}
        onClick={cancel}
        className="bg-brand-gray hover:bg-brand-red1 focus:bg-brand-red1 
        active:bg-brand-red0"
        isDisabled={isDisabled}
      />
      <Button
        type="submit"
        title={i18n["save"]}
        isDisabled={isDisabled}
        isLoading={isSubmitLoading}
      />
    </>
  );
};
