import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  TestingArchiveData,
  TestingArchiveFileFormData,
} from "../../../../types/TestingArchiveData";
import { TestingArchiveModalFileData } from "../TestingArchive";
import { UploadModalEditContent } from "./UploadModalEditContent";
import { UploadModalViewContent } from "./UploadModalViewContent";
import { authenticatedFetch } from "../../../../service/api";

export type FileUpdateDataProps =
  | { onSuccessCB: () => void } & (
      | {
          data: TestingArchiveFileFormData;
          type: "ADD";
        }
      | {
          data: TestingArchiveData;
          type: "UPDATE" | "DELETE";
        }
    );

export const UploadModalContent = ({
  isContentEditable,
  modalData,
  title,
  cancel,
  closeModal,
  switchMode,
}: {
  isContentEditable: boolean;
  modalData: TestingArchiveModalFileData;
  title: string;
  cancel: () => void;
  closeModal: () => void;
  switchMode: () => void;
}) => {
  const { memberId } = useParams();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async ({ data, type }: FileUpdateDataProps) => {
      let response = { ok: false } as Response;
      if (type === "ADD") {
        response = await authenticatedFetch(
          "member/" + memberId + "/testing-archive/file",
            'PUT',
            data,
        );
      }
      if (type === "UPDATE") {
        response = await authenticatedFetch(
          "member/" + memberId + "/testing-archive/file/" + data.id,
            'POST',
            data,
        );
      }
      if (type === "DELETE") {
        response = await authenticatedFetch(
          "member/" + memberId + "/testing-archive/" + data.id,
            'DELETE',
        );
      }
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      onSuccess: async (data, { onSuccessCB }) => {
        await queryClient.invalidateQueries(["testingArchive", memberId]);
        onSuccessCB();
      },
      onError: async () => {},
    }
  );

  return isContentEditable ? (
    <UploadModalEditContent
      modalData={modalData}
      updateData={(props) => mutate(props)}
      isUpdating={isLoading}
      title={title}
      closeModal={closeModal}
      cancel={cancel}
    />
  ) : (
    <UploadModalViewContent
      data={modalData.defaultValues as TestingArchiveData}
      updateData={(props) => mutate(props)}
      isUpdating={isLoading}
      title={title}
      closeModal={closeModal}
      switchMode={switchMode}
    />
  );
};
