import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const HomeStep = ({
  step,
  title,
  children,
  isDisabled,
}: {
  step: number;
  title: string;
  children: ReactNode;
  isDisabled?: boolean;
}) => {
  return (
    <div className={twMerge("flex flex-col gap-6", isDisabled && "opacity-50")}>
      <div className="flex gap-6">
        <div className="flex justify-center items-center w-10 h-10 flex-shrink-0 bg-brand-primary rounded-full text-xl text-white font-semibold">
          {step}
        </div>
        <h2 className="text-3xl">{title}</h2>
      </div>

      {children}
    </div>
  );
};
