import { Button } from "../../../components/Button";
import { ModalBody } from "../../../components/Modal/ModalBody";
import { useI18n } from "../../../translation";

export const TestingArchiveModalOptions = ({
  openFmsForm,
  openUploadForm,
}: {
  openFmsForm: () => void;
  openUploadForm: () => void;
}) => {
  const i18n = useI18n();

  return (
    <ModalBody>
      <div className="flex flex-col gap-y-6 py-4">
        <Button
          title={i18n["memberDetails.testingArchive.options.startFMS"]}
          onClick={openFmsForm}
        />
        <hr className="border-gray-300" />
        <Button
          title={i18n["memberDetails.testingArchive.options.upload"]}
          onClick={openUploadForm}
        />
      </div>
    </ModalBody>
  );
};
