import React from "react";
import {MemberDetails} from "./pages/memberDetails/MemberDetails";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import {ErrorPage} from "./pages/errorPage/ErrorPage";
import {Home} from "./pages/home/Home";
import Layout from "./pages/layout/Layout";
import {Login} from "./pages/layout/Login";
import {RequireAuthentication} from "./pages/layout/RequireAuthentication";
import {AuthProvider} from "./pages/layout/Authentication";
import {Toaster} from "react-hot-toast";
import {Style} from "./components/Style";


export const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<ErrorPage/>} element={<Layout/>}>
        <Route path="/login" element={<Login/>}/>
        <Route index element={<RequireAuthentication>
          <>
            <Style />
            <Home/>
          </>
        </RequireAuthentication>}/>
        <Route path="members/:memberId" element={<RequireAuthentication>
          <>
            <Style />
            <MemberDetails/>
          </>
        </RequireAuthentication>}/>
      </Route>
    )
  );
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  if (token) {
    localStorage.setItem('sitzkrieger-token', token);
    window.location.search = '';
  }

  return <AuthProvider>
    <Toaster/>
    <RouterProvider router={router}/>
  </AuthProvider>;
};
