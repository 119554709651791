import {useState} from "react";
import {FaPen} from "react-icons/fa";
import {MemberData} from "../../../types/MemberData";
import {useI18n} from "../../../translation";
import {CardTitle} from "../components/CardTitle";
import {RedFlagsModal} from "./RedFlagsModal";
import parse from "html-react-parser";
import {MultiInputView} from "../../../components/form/MultiInputEdit";
import {BsFlagFill} from "react-icons/bs";

export const RedFlags = ({data}: { data: MemberData }) => {
  // todo: refactor component with goals component to be one element
  const i18n = useI18n();
  const [isModalOpen, setIsModalOpen] = useState(false);

  let isContentJson = false;
  let content: string | JSX.Element | JSX.Element[] = '';
  if (data.redFlags !== '') {
    try {
      const json = JSON.parse(data.redFlags);
      if ((typeof json === 'object')) {
        content = <MultiInputView renderListIcon={<BsFlagFill className="text-lg text-brand-red1 leading-6 mr-2"/>}
                                  json={json}/>;
        isContentJson = true;
      }
    } catch (e) {
    }
    if (!isContentJson) {
      content = parse(data.redFlags);
    }
  } else {
    isContentJson = true;
  }

  return (
    <div className="flex flex-1 flex-col p-4 border-2 rounded-xl">
      <CardTitle
        title={i18n["memberDetails.flags.title"]}
        onClick={() => setIsModalOpen(true)}
        icon={<FaPen/>}
      />
      <div className="overflow-auto mt-6 h-52 prose">
        {content}
      </div>

      {isModalOpen && (
        <RedFlagsModal
          defaultValues={data}
          label={i18n["memberDetails.flags.title"]}
          closeModal={() => setIsModalOpen(false)}
          useMultiTextInput={isContentJson}
        />
      )}
    </div>
  );
};
