import { MovementMatrixData } from "../../../types/MovementMatrixData";
import { useI18n } from "../../../translation";
import { MovementMatrixCell } from "./components/MovementMatrixCell";

export const PronePattern = ({ data, fullScreen }: { data: MovementMatrixData, fullScreen: boolean }) => {
  const i18n = useI18n();

  return (
    <div className={'flex flex-1 flex-col ' + (fullScreen ? 'lg:border-r-2' : 'xl:border-r-2')}>
      <div className="py-4 flex justify-center text-brand-primary font-semibold border-b-2">
        {i18n["memberDetails.movementMatrix.pronePattern"]}
      </div>
      <div className={'flex flex-col ' + (fullScreen ? 'md:max-lg:flex-row' : 'md:max-xl:flex-row')}>
        <MovementMatrixCell
          cellValue={data["kniebeuge"]}
          className={(fullScreen ? 'md:max-lg:border-r-2' : 'md:max-xl:border-r-2')}
          label={i18n["memberDetails.movementMatrix.squat"]}
        />
        <MovementMatrixCell
          cellValue={data["liegestuetze"]}
          label={i18n["memberDetails.movementMatrix.pushUp"]}
        />
      </div>

      <div className="flex">
        <MovementMatrixCell
          cellValue={data["prone-plank"]}
          className="border-r-2"
          isHalfSize
          label={i18n["memberDetails.movementMatrix.pronePlank"]}
        />
        <MovementMatrixCell
          cellValue={data["m-flexion"]}
          isHalfSize
          label={i18n["memberDetails.movementMatrix.mFlexion"]}
        />
      </div>

      <MovementMatrixCell
        cellValue={data["vierfuesslerstand"]}
        label={i18n["memberDetails.movementMatrix.quadrupedStand"]}
      />
    </div>
  );
};
