import { ReactNode, useState } from "react";
import { Modal } from "../../../../components/Modal/Modal";
import { getIsoDate } from "../../../../utils/dateUtils";
import { CardTitle } from "../CardTitle";
import { HistoryListContainer } from "./HistoryListContainer";
import { HistoryListModalContent } from "./HistoryListModalContent";
import { HistoryListModalEditContent } from "./HistoryListModalEditContent";
import {
  HistoryListData,
  HistoryListFormData,
} from "../../../../types/HistoryListData";

export type UpdateDataProps =
  | { onSuccessCB: () => void } & (
      | {
          data: HistoryListFormData;
          type: "ADD";
        }
      | {
          data: HistoryListData;
          type: "UPDATE" | "DELETE";
        }
    );

export type HistoryListModalData =
  | {
      defaultValues: HistoryListData;
      isNewEntry?: false;
    }
  | {
      defaultValues: HistoryListFormData;
      isNewEntry: true;
    };

export const HistoryList = ({
  title,
  renderContent,
  updateData,
  isUpdating,
}: {
  title: string;
  renderContent: ({
    openEntry,
  }: {
    openEntry: (defaultValues: HistoryListData) => void;
  }) => ReactNode;
  updateData: (props: UpdateDataProps) => void;
  isUpdating: boolean;
}) => {
  const [modalData, setModalData] = useState<HistoryListModalData>();
  const [showEditContent, setShowEditContent] = useState(false);
  const [showLargeModal, setShowLargeModal] = useState(false);

  const addNewEntry = () => {
    setShowEditContent(true);
    setModalData({
      defaultValues: { datetime: getIsoDate(new Date())!, title: "", note: "" },
      isNewEntry: true,
    });
  };
  const openEntry = (defaultValues: HistoryListData) => {
    setShowEditContent(false);
    setModalData({
      defaultValues: {
        ...defaultValues,
        datetime: getIsoDate(defaultValues.datetime)!,
      },
    });
  };
  const switchMode = () => setShowEditContent((old) => !old);
  const closeModal = () => setModalData(undefined);

  return (
    <HistoryListContainer>
      <CardTitle title={title} onClick={addNewEntry} />

      {renderContent({ openEntry })}

      {modalData && (
        <Modal closeModal={closeModal} showLargeModal={showLargeModal}>
          {showEditContent ? (
            <HistoryListModalEditContent
              modalData={modalData}
              updateData={updateData}
              isUpdating={isUpdating}
              title={title}
              closeModal={closeModal}
              switchMode={switchMode}
            />
          ) : (
            <HistoryListModalContent
              data={modalData.defaultValues as HistoryListData}
              updateData={updateData}
              isUpdating={isUpdating}
              title={title}
              closeModal={closeModal}
              switchMode={switchMode}
              showLargeModal={setShowLargeModal}
            />
          )}
        </Modal>
      )}
    </HistoryListContainer>
  );
};
