import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const HistoryListContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        "flex flex-1 flex-col p-4 border-2 rounded-xl min-w-0",
        className
      )}
    >
      {children}
    </div>
  );
};
