import { useState } from "react";
import { FaPen } from "react-icons/fa";
import { MemberData } from "../../../types/MemberData";
import { useI18n } from "../../../translation";
import { CardTitle } from "../components/CardTitle";
import { GoalsModal } from "./GoalsModal";
import parse from "html-react-parser";
import {MultiInputView} from "../../../components/form/MultiInputEdit";

export const Goals = ({ data }: { data: MemberData }) => {
  const i18n = useI18n();
  const [isModalOpen, setIsModalOpen] = useState(false);

  let isContentJson = false;
  let content: string|JSX.Element|JSX.Element[] = '';
  if (data.goal !== '') {
    try {
      const json = JSON.parse(data.goal);
      if ((typeof json === 'object')) {
        content = <MultiInputView json={json} />;
        isContentJson = true;
      }
    } catch (e) {}
    if (!isContentJson) {
      content = parse(data.goal);
    }
  } else {
    isContentJson = true;
  }

  return (
    <div className="flex flex-1 flex-col p-4 border-2 rounded-xl">
      <CardTitle
        title={i18n["memberDetails.goals.title"]}
        onClick={() => setIsModalOpen(true)}
        icon={<FaPen />}
      />
      <div className="overflow-auto mt-6 h-52 prose">
        {content}
      </div>

      {isModalOpen && (
        <GoalsModal
          defaultValues={data}
          label={i18n["memberDetails.goals.title"]}
          closeModal={() => setIsModalOpen(false)}
          useMultiTextInput={isContentJson}
        />
      )}
    </div>
  );
};
