import { twMerge } from "tailwind-merge";

export const Spinner = ({ className }: { className?: string }) => {
  return (
    <div
      className={twMerge(
        "spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-brand-primary",
        className
      )}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
