import { ReactNode } from "react";
import { useI18n } from "../../../../../translation";

export const FmsRadioColumnDoubler = ({
  categoryKey,
  renderColumn,
}: {
  categoryKey: string;
  renderColumn: (isLeft: boolean) => ReactNode;
}) => {
  const i18n = useI18n();
  const columns = [
    {
      title: i18n["memberDetails.testingArchive.fms.radioColumn.left.short"],
      isLeft: true,
    },
    {
      title: i18n["memberDetails.testingArchive.fms.radioColumn.right.short"],
      isLeft: false,
    },
  ];

  return (
    <div className="flex flex-col gap-x-16 md:flex-row">
      {columns.map(({ title, isLeft }) => (
        <div
          key={categoryKey + title}
          className="flex flex-row gap-8 md:flex-col md:items-center md:gap-0"
        >
          <p className="font-semibold text-lg self-center md:text-base md:pl-[73px]">
            {title}
          </p>
          <div className="flex mt-2">{renderColumn(isLeft)}</div>
        </div>
      ))}
    </div>
  );
};
