import {Controller, useForm} from "react-hook-form";
import { DatePicker } from "../../../../components/form/DatePicker";
import { Input } from "../../../../components/form/Input";
import { Textarea } from "../../../../components/form/Textarea";
import { ModalBody } from "../../../../components/Modal/ModalBody";
import { ModalFooter } from "../../../../components/Modal/ModalFooter";
import { ModalFooterButtons } from "../../../../components/Modal/ModalFooterButtons";
import { ModalHeader } from "../../../../components/Modal/ModalHeader";
import { useI18n } from "../../../../translation";
import { HistoryListData } from "../../../../types/HistoryListData";
import { HistoryListModalData, UpdateDataProps } from "./HistoryList";

export const HistoryListModalEditContent = ({
  modalData,
  updateData,
  isUpdating,
  title,
  closeModal,
  switchMode,
}: {
  modalData: HistoryListModalData;
  updateData: (props: UpdateDataProps) => void;
  isUpdating: boolean;
  title: string;
  closeModal: () => void;
  switchMode: () => void;
}) => {
  const i18n = useI18n();
  const { defaultValues, isNewEntry } = modalData;
  const formMethods = useForm<HistoryListModalData["defaultValues"]>({
    defaultValues,
  });
  const { register, handleSubmit } = formMethods;
  const onSubmit = (data: HistoryListModalData["defaultValues"]) => {
    if (isNewEntry) updateData({ data, type: "ADD", onSuccessCB: closeModal });
    else
      updateData({
        data: data as HistoryListData,
        type: "UPDATE",
        onSuccessCB: closeModal,
      });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader title={title} closeModal={closeModal} />
      <ModalBody>
        <div className="flex flex-col gap-y-6">
          <Input
            label={i18n["memberDetails.trainingHistory.modal.form.title"]}
            {...register("title", { required: true })}
          />
          <DatePicker
            label={i18n["date"]}
            {...register("datetime", { required: true })}
          />

          <Controller
            render={({field}) =>
              <Textarea field={field}
                        id={'memberDetails-trainingHistory-modal-form-notes'}
                        label={i18n["memberDetails.trainingHistory.modal.form.notes"]}
                        className={'h-44'}
              />
            }
            name="note"
            control={formMethods.control}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons
          isSubmitLoading={isUpdating}
          cancel={isNewEntry ? closeModal : switchMode}
        />
      </ModalFooter>
    </form>
  );
};
