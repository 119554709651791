import {FormProvider, useForm} from "react-hook-form";
import {ModalBody} from "../../../../components/Modal/ModalBody";
import {ModalFooter} from "../../../../components/Modal/ModalFooter";
import {ModalFooterButtons} from "../../../../components/Modal/ModalFooterButtons";
import {useI18n} from "../../../../translation";
import {
  TestingArchiveData,
  TestingArchiveFMSFormData,
} from "../../../../types/TestingArchiveData";
import {TestingArchiveModalFMSData} from "../TestingArchive";
import {FmsContentRow} from "./components/FmsContentRow";
import {FMSUpdateDataProps} from "./FmsModalContent";
import {ASSET_URL} from "../../../../service/config";
import {ModalHeader} from "../../../../components/Modal/ModalHeader";

export const FmsModalEditContent = ({
  modalData,
  updateData,
  isUpdating,
  title,
  closeModal,
  cancel,
}: {
  modalData: TestingArchiveModalFMSData;
  updateData: (props: FMSUpdateDataProps) => void;
  isUpdating: boolean;
  title: string;
  closeModal: () => void;
  cancel: () => void;
}) => {
  const i18n = useI18n();
  const {defaultValues, isNewEntry} = modalData;
  const formMethods = useForm<TestingArchiveModalFMSData["defaultValues"]>({
    defaultValues,
  });
  const {handleSubmit} = formMethods;
  const onSubmit = (data: TestingArchiveModalFMSData["defaultValues"]) => {
    if (isNewEntry)
      updateData({
        data: data as TestingArchiveFMSFormData,
        type: "ADD",
        onSuccessCB: closeModal,
      });
    else
      updateData({
        data: data as TestingArchiveData,
        type: "UPDATE",
        onSuccessCB: closeModal,
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form className="contents" noValidate onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader title={title} closeModal={closeModal}/>
        <ModalBody className="h-full overflow-hidden">
          <div className="flex flex-col gap-y-6 h-full">
            <div className="flex flex-col gap-y-10 overflow-auto">
              <FmsContentRow
                categoryKey={"kniebeuge"}
                title={i18n["memberDetails.testingArchive.fms.squat"]}
                imgSrc={ASSET_URL + 'kniebeuge.png'}
              />
              <FmsContentRow
                categoryKey={"tspu"}
                title={i18n["memberDetails.testingArchive.fms.tspu"]}
                imgSrc={ASSET_URL + 'tspu.png'}
              />
              <FmsContentRow
                categoryKey={"schulter"}
                title={
                  i18n["memberDetails.testingArchive.fms.shoulderMobility"]
                }
                imgSrc={ASSET_URL + 'schulter.png'}
                showDoubleRow
              />
              <FmsContentRow
                categoryKey={"handgelenk"}
                title={i18n["memberDetails.testingArchive.fms.wristMobility"]}
                imgSrc={ASSET_URL + 'handgelenk.png'}
                showDoubleRow
              />
              <FmsContentRow
                categoryKey={"aslr"}
                title={i18n["memberDetails.testingArchive.fms.aslr"]}
                imgSrc={ASSET_URL + 'aslr.png'}
                showDoubleRow
              />
              <FmsContentRow
                categoryKey={"sprunggelenk"}
                title={i18n["memberDetails.testingArchive.fms.ankleMobility"]}
                imgSrc={ASSET_URL + 'sprunggelenk.png'}
                showDoubleRow
              />
              <FmsContentRow
                categoryKey={"romberg"}
                title={i18n["memberDetails.testingArchive.fms.romberg"]}
                imgSrc={ASSET_URL + 'romberg.png'}
                hideClearing={true}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons cancel={cancel} isSubmitLoading={isUpdating}/>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
