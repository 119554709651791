import {ReactElement, useState} from "react";
import {CircleButton} from "../../../components/CircleButton";
import {useNavigate} from "react-router-dom";
import {useMutation} from "react-query";
import {MemberData} from "../../../types/MemberData";
import {authenticatedFetch} from "../../../service/api";
import {useI18n} from "../../../translation";
import {MemberModal} from "./MemberModal";

export const AddMember = ({projectId}: {projectId: string}): ReactElement => {
    const i18n = useI18n();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const navigate = useNavigate();
    const addMutation = useMutation(
        async (newInfo: MemberData["info"]) => {
            const response = await authenticatedFetch(
                "member/",
                "PUT",
                { ...newInfo, project: projectId },
            );
            if (!response.ok) throw new Error();
            return response.json();
        },
        {
            onSuccess: async ({ id }) => {
                setIsAddModalOpen(false);
                navigate(`/members/${id}`);
            },
            onError: async () => {},
        }
    );
    const onSubmitAddModal = (data: MemberData["info"]) =>
        addMutation.mutate(data);

    return <>
        <div className="flex gap-x-3 items-center">
            <CircleButton onClick={() => setIsAddModalOpen(true)} />
            <p>{i18n["actionHeader.addMember"]}</p>
        </div>
        {isAddModalOpen ? (
            <MemberModal
                label={i18n["memberModal.title"]}
                onSubmit={onSubmitAddModal}
                isLoading={addMutation.isLoading}
                closeModal={() => setIsAddModalOpen(false)}
            />
        ) : null}
    </>
};