import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {Spinner} from "../../components/Spinner";
import {MemberData} from "../../types/MemberData";
import {ProjectData} from "../../types/ProjectData";
import {ErrorPage} from "../errorPage/ErrorPage";
import {ActionHeader} from "./ActionHeader/ActionHeader";
import {MemberInfo} from "./MemberInfo";
import {Goals} from "./Goals/Goals";
import {MedicalHistory} from "./MedicalHistory/MedicalHistory";
import {MovementMatrix} from "./MovementMatrix/MovementMatrix";
import {TestingArchive} from "./TestingArchive/TestingArchive";
import {TrainingHistory} from "./TrainingHistory/TrainingHistory";
import {authenticatedFetch} from "../../service/api";
import {RedFlags} from "./RedFlags/RedFlags";
import {HealthScoreList} from "./HealthScoreList";

export const MemberDetails = () => {
  const {memberId} = useParams();
  const {isLoading, isError, data} = useQuery<MemberData>(
    ["member", memberId],
    async () => {
      const response = await authenticatedFetch("member/" + memberId);
      if (!response.ok) throw new Error();
      return response.json();
    }
  );

  const {data: project} = useQuery<ProjectData>(
    ["project", data?.projectId],
    async () => {
      const response = await authenticatedFetch("projects/" + data?.projectId);
      if (!response.ok) throw new Error();
      return response.json();
    },
    {
      enabled: !!data?.projectId,
    }
  );

  if (isLoading)
    return (
      <div className="flex flex-1 justify-center">
        <Spinner/>
      </div>
    );
  if (isError || !data) return <ErrorPage/>;

  return (
    <>
      <div className="flex flex-col gap-y-8">
        <h1 className="text-lg font-semibold -mb-2 h-7">{project?.name || ""}</h1>
        <ActionHeader data={data}/>
        <div className="flex gap-8 flex-col xl:flex-row">
          <MemberInfo data={data} project={project} />
          <div className="flex flex-1 flex-col gap-6">
            <Goals data={data}/>
            <RedFlags data={data}/>
          </div>
        </div>
        <MovementMatrix member={data}/>
        <div className="flex gap-8 flex-col xl:flex-row">
          <TrainingHistory/>
          <MedicalHistory/>
        </div>
        <div className="flex gap-8 flex-col xl:flex-row">
          <TestingArchive/>
        </div>
        {data.sitzkriegerId !== -1 ? <>
          <HealthScoreList />
        </> : <></>}
      </div>
    </>
  );
};
